import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewEncapsulation,
  ElementRef,
  Inject,
  AfterViewInit,
} from '@angular/core';
import { NavItem } from 'epubjs/types/navigation';
import { EpubRenderService } from '../../services/rendition/epubrender.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from '../../../util/device-detector/device-detector.service';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-table-contents',
  templateUrl: './table-contents.component.html',
  styleUrls: ['./table-contents.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class TableContentsComponent implements OnInit, AfterViewInit {
  public activatedView = 'book';

  tocValue: any;
  @Input() tableOfContentsOpened: boolean | undefined;
  @Input() chapters: NavItem[] | undefined;
  @Input() toc: any;
  @Output() onNavigate = new EventEmitter<any>();
  @Output() onToggleTableOfContents = new EventEmitter<any>();
  @Output() onTogglePanel = new EventEmitter<any>();

  constructor(
    private rendering: EpubRenderService,
    public sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    public deviceDetector: DeviceDetectorService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  /**
   * Inject toc html to the component.
   */
  ngOnInit(): void {
    if (this.toc) {
      this.tocValue = this.sanitizer.bypassSecurityTrustHtml(this.toc);
    }
  }

  /**
   * Set up the table of contents after the view has been initialized.
   */
  ngAfterViewInit(): void {
    this.setUpTOC();
  }

  /**
   * Attaches click event to the table of contents to navigate to the chapter.
   */
  setUpTOC(): void {
    let ul = this.elementRef.nativeElement.shadowRoot.querySelector('#TOC');
    if (ul) {
      let anchors = ul.querySelectorAll('a');
      for (let anchor of anchors) {
        anchor.addEventListener('click', () => {
          this.goToPageToc(anchor.id);
        });
      }
    }
  }

  /**
   * This function switches to the href of the chapter.
   * @param href The href of the chapter to go to.
   */
  public goToPageToc(href: string): void {
    this.rendering.getCfiFromHref(href).then((cfi) => {
      if (cfi) {
        this.rendering.goToCfi(cfi).then(() => {
          this.onNavigate.emit(cfi);
          const hash = href.substring(href.indexOf('#'));
          setTimeout(() => {
            this.rendering.goToSubSection(hash);
          }, 500);
        });
      }
    });
  }

  /**
   * This functions directs the user to specific chapter, sub chapter or item in the book.
   * @param chapter The chapter to go to.
   */
  public goToSpecificPage(chapter: NavItem): void {
    const href = chapter.href;
    this.rendering.getCfiFromHref(href).then((cfi) => {
      if (cfi) {
        this.rendering.goToCfi(cfi).then(() => {
          this.onNavigate.emit(cfi);
        });
      }
    });
  }

  /**
   * This function is used to close the table of contents in the mobile view. 
   */
  public exitEditor() {
    this.onTogglePanel.emit({ navigateTo: 'book', editing: false });
  }
}
