import { HelpComponent } from './help/help.component';
import { Component, Inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DeviceDetectorService } from '../../util/device-detector/device-detector.service';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
})
export class ContactSupportComponent{
  constructor(
    private modalCtrl: ModalController,
    public deviceDetector: DeviceDetectorService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  public async openTipps(tipp: string){
    await this.modalCtrl.dismiss();
    let cssClass = this.deviceDetector.isDesktop() ? 'ion-main-modal' : 'ion-main-modal-mobile';
    const popover = await this.modalCtrl.create({
      component: HelpComponent,
      cssClass: cssClass,
      componentProps: {
        Tipp: tipp
      }
  })
  return await popover.present();
}

 public async closeModal() {
    await this.modalCtrl.dismiss();
  }
}
