import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ThemeService } from '../services/themes/theme.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeInitializationGuard  {

  constructor(
    private themeService: ThemeService
  ) {}

  canActivate(): Observable<boolean> {
    return this.themeService.isInitialized;
  }
}
