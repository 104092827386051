<ion-content
  class="collection-overview-content"
  id="collection-overview-content"
>
  <div class="collectionContainer">
    <ng-container *ngFor="let book of collection">
      <div class="book" *ngIf="book.id !== currentlyOpenedBook.id">
        <div class="book__title">
          {{book.title}}
        </div>
        <div class="book__container">
          <div class="image">
            <img [src]="book.urlCover">
            <ion-button (click)="openBook(book)" l10nTranslate>OfflineMode.Open</ion-button>
          </div>
          <div class="analytics">
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ion-content>
