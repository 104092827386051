export const i18nAsset = {
    'de-DE': {
        "Check": "Was ist los?",
        "UnknownError": "Ups! Irgendwas lief schief.\n\nVersuchen Sie, diese Seite zu aktualisieren, oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.",
        "FileSizeError": "Die Dateigröße überschreitet die Grenze von 350kB.",
        "UploadSuccess": "Datei erfolgreich hochgeladen!",
        "Books": {
            "Title": "Meine Workbooks",
            "WelcomeTitle": "Willkommen in deinem Litello Desktop-Bereich!",
            "WelcomeMessage": "Deine gekauften Litello eBooks kannst du in deiner Litello App downloaden und stehen dir anschließend auch hier zur Verfügung. Und das hat viele Vorteile: Denn du kannst stets an der Stelle im eBook weiterarbeiten, an der du mobil aufgehört hast, und andersrum. Das macht dich beim Lernen super flexibel. Außerdem kannst du dir ganz bequem Notizen machen, diese speichern und dich, genauso wie an deinem Smartphone, mit deiner Lerngruppe austauschen. Du siehst: Ob unterwegs oder Zuhause, so wird das Lernen zum Kinderspiel.",
            "LastOpenedAt": "Zuletzt geöffnet",
            "Contents": "Inhalt",
            "Downloading": "Geladen",
            "Initializing": "Initialisieren",
            "Unzipping": "Entpacken"
        },
        "Footer": {
            "PrivacyPolicy": "Datenschutz"
        },
        "Book": {
            "Of": "von",
            "download": {
                "start": "Vorgang wurde gestartet",
                "success": "Vorgang wurde erfolgreich abgeschlossen",
                "failure": "Server Fehler! Versuche es später noch einmal",
                "server": {
                    "glitch": "Server Fehler! Bitte versuche es erneut"
                }
            },
            "NotFound": "Buch nicht gefunden.\n\nVersuchen Sie, diese Seite zu aktualisieren, oder kontaktieren Sie uns, wenn das Problem weiterhin besteht."
        },
        "Collections": {
            "Collection": "Sammlung"
        },
        "Communication": {
            "Posts": "Beiträge",
            "Comments": "Comments",
            "WritePost": "Beitrag verfassen...",
            "NoGroups": "Keine Gruppen zur Auswahl.",
            "NoThreads": "Keine Beiträge.",
            "SuccessfullyDeleted": "Erfolgreich gelöscht."
        },
        "NetworkRequired": "Sie müssen mit einem Netzwerk verbunden sein, um diese Funktion nutzen zu können.",
        "Analytics": {
            "Progress": "Fortschritt"
        },
        "Contextmenu": {
            "PrivateNoteLabel": "Notiz",
            "Copied": "Kopiert!"
        },
        "Bookmenu": {
            "TableOfContent": "Inhaltsverzeichnis",
            "BackArrow": "Zurück zur vorherigen Position"
        },
        "Search": {
            "Title": "Suche",
            "Search": "suchen",
            "ResultsFor": "Ergebnisse für",
            "Back": "zurück",
            "Next": "weiter",
            "List": "Liste",
            "Single": "Einzel",
            "PageAbbreviation": "Seite",
            "Keyword": "Suchbegriff",
            "KeywordTooShort": "Der Suchbegriff muss mindestens 3 Zeichen lang sein.",
        },
        "Attachment": {
            "Download": "Downloads",
            "Title": "Attachment",
            "AllCategories": "Alle Kategorien",
            "AddedOn": "Hinzugefügt am",
            "ShowAllAttachments": "Alle Downloads anzeigen",
            "AlreadyDownloaded": "Sie haben bereits heruntergeladen {{ name }}. Möchten Sie es erneut herunterladen?",
            "Open": "Öffnen",
            "DownloadAgain": "Erneut herunterlade",
            "DownloadError": "Etwas ist schief gelaufen",
            "NoAttachments": "Es gibt keine Anhänge für dieses Buch!"
        },
        "ImageModal": {
            "Image": "Bild",
            "Back": "Zurück"
        },
        "Dialog": {
            "AlertMessage": "Achtung! Ihre Änderungen gehen verloren, wenn Sie diese nicht speichern.",
            "Cancel": "Abbrechen",
            "Save": "speichern",
            "DoNotSave": "Nicht speichern",
            "Delete": "Löschen",
            "OK": "OK",
            "SyncBeforeDelete": "Du musst erst deine Änderungen synchronisieren, bevor du das Buch löschst",
            "Close": "Schließen"
        },
        "Menu": {
            "shop": "Zum Shop",
            "AboutLitello": "Über Litello",
            "Credits": "Credits",
            "Changelog": "Changelog",
            "Imprint": "Impressum",
            "DataPrivacy": "Datenschutzerklärung",
            "Support": "Hilfe und Support",
        },
        "MenuTooltip": {
            "Edit": "Bearbeiten",
            "Locate": "Lokalisieren",
            "Delete": "Löschen"
        },
        "Credits": {
            "Credits": "Credits",
            "Component": "Component",
            "Owner": "Owner",
            "License": "License",
            "NetworkMessage": "Du musst eine Internetverbindung haben, damit du die Credits sehen kannst."
        },

        "HelpAndSupport": {
            "Header": "Hilfe und Support",
            "Title": "Welche Funktionen stehen ihnen zur Verfügung",
            "IntroText": "Hier finden Sie Hilfestellungen für die einzelnen Funktionen in Litello. Sollten dennoch Fragen unbeantwortet bleiben oder technische Probleme auftreten, kontaktieren Sie den Support.",
            "Bib": "Bibliothek",
            "Search": "Suche",
            "Marks": "Markierungen",
            "Downloads": "Downloads",
            "ContactSupport": "Support kontaktieren"
        },
        "Support": {
            "Header": "Support kontaktieren",
            "BackButton": " zurück ",
            "Title": " Support kontaktieren",
            "IntroText": "Haben sie Probleme mit der Anwendung oder einem litelloBook? Dann schreiben Sie uns. Bitte wählen Sie den Betreff über die Dropdowns aus und fügen eine kurze Nachricht an, welche das Problem grob beschreibt.",
            "Email1": "Kontakt E-Mail Adresse*",
            "Email2": "E-Mail Adresse bestätigen*",
            "EmailError1": "Geben sie bitte eine Email im richtigen Format ein",
            "EmailError2": "Bitte Email eintragen",
            "EmailError3": "Die Emails müssen übereinstimmen!",
            "Subject": "Betreff",
            "Subject1": "Problem mit einem LitelloBook",
            "Subject2": "Problem mit dem Webreader",
            "Subject3": "Sonstiges",
            "SubjectError1": "Bitte Betreff auswählen!",
            "SubjectValue": "Betreff eintragen*",
            "Book": "Buch wählen*",
            "SubjectValueError": "Bitte Betreff eintragen*",
            "BookError": "Bitte Buch auswählen*",
            "Message": "Nachricht*",
            "MessageError": "Bitte Nachricht eintragen",
            "Notice": "(Um Ihr Anliegen zu bearbeiten, benötigen wir eine Kontakt Email-Adresse. Diese wird ausschließlich zur Beantwortung ihrer Anfrage genutzt)*",
            "SendButton": "Support kontaktieren",
            "emailPlaceholder": "Email",
            "subjectPlaceholder": "Betreff wählen",
            "bookPlaceholder": "Buch wählen",
            "subjectValuePlaceholder": "Grund"
        },
        "Help": {
            "HeaderMarks": "Hilfe und Support | Markierungen",
            "HeaderSearch": "Hilfe und Support | Suche",
            "HeaderDownloads": "Hilfe und Support | Downloads",
            "BackButton": " zurück "
        },
        "DataPrivacy": {
            "Title": "Datenschutzerklärung"
        },
        "Imprint": {
            "Title": "Impressum"
        },

        "Changelog": {
            "Changelog": "Changelog"
        },
        "Settings": {
            "NetworkConnected": "Network Connected",
            "NetworkDisconnected": "Network Disconneted"
        },
        "OfflineMode": {
            "Download": "Herunterladen",
            "Open": "Öffnen"
        },
        "InternetConnection": {
            "InOfflineMode": "Keine Internetverbindung. Du befindest dich nun im Offline-Modus.",
            "NoInternetConnection": "Keine Internetverbindung.",
            "Online": "Online!"
        },
        "Toast": {
            "Close": "Zum Schließen klicken",
            "Favorite": "Favoriten",
            "Done": "Erledigt"
        }
    },
    "en-GB": {
        "Check": "Whats going on?",
        "UnknownError": "Oops something went wrong. \n\nTry to refresh this page or feel free to contact us if the problem persists.",
        "FileSizeError": "The file size exceeds the limit of 350kB.",
        "UploadSuccess": "File successfully uploaded!",
        "Books": {
            "Title": "My workbooks",
            "WelcomeTitle": "Welcome to your Litello desktop area!",
            "WelcomeMessage": "You can download your purchased Litello e-books in the Litello app, after which they will also be available here. This has many benefits: You can pick up in the e-book exactly where you left off on your mobile device, and vice versa. This makes learning really flexible. You can also conveniently create notes, save them and share them with your learning group, just like on your smartphone. You will see that learning is fun whether you’re on the go or at home.",
            "LastOpenedAt": "Last opened",
            "Contents": "Contents",
            "Downloading": "Downloading",
            "Initializing": "Initializing",
            "Unzipping": "Unzipping"
        },
        "Footer": {
            "PrivacyPolicy": "Privacy Policy"
        },
        "Book": {
            "Of": "from",
            "download": {
                "start": "Process started",
                "success": "Process completed successfully",
                "failure": "Server error! Try again later",
                "server": {
                    "glitch": "Server error! Try again"
                }
            },
            "NotFound": "Book not found.\n\nTry to refresh this page or feel free to contact us if the problem persists."
        },
        "Collections": {
            "Collection": "Collection"
        },
        "Communication": {
            "Posts": "Posts",
            "Comments": "Comments",
            "WritePost": "Write a post...",
            "NoGroups": "No groups to select.",
            "NoThreads": "No posts.",
            "SuccessfullyDeleted": "Successfully deleted."
        },
        "NetworkRequired": "You need to be connected to a network in order to use this feature.",
        "Analytics": {
            "Progress": "Progress"
        },
        "Contextmenu": {
            "PrivateNoteLabel": "Note",
            "Copied": "It is copied!"
        },
        "Group": {
            "Username": "Username",
            "Title": "My groups",
            "InvitesTitle": "My invitations",
            "CreateGroup": "Create group",
            "GroupName": "Group name",
            "cancel": "Cancel",
            "back": "Back",
            "addMember": "Add",
            "inputEmail": "User’s email address",
            "sendInvite": "Send invitations",
            "Delete": "Delete",
            "Groups": "Group",
            "Invites": "Invitations",
            "Accept": "Accept",
            "Decline": "Decline",
            "alternative": "or upload an Excel file with e-mail addresses as a list: ",
            "SelectFile": "Select file...",
            "from": "From:",
            "InviteEmailError": "Please check that all email addresses were entered correctly. You can separate multiple email addresses using , or ;",
            "InvitationFailed": "The invitations could not be sent. Please check the details you entered or try again later",
            "MemberAlreadyInGroup": "User is already a member of this group.",
            "MemberNotExists": "User not exists.",
            "CreateGroupPatternError": "You can only use letters, numbers, spaces and the characters '&’ and '-'",
            "CreateGroupFailed": "Unfortunately, the group could not be created Please check the details you entered or try again later",
            "role": "Role:"
        },
        "Bookmenu": {
            "TableOfContent": "Table of Contents",
            "BackArrow": "Back to previous position"
        },
        "Search": {
            "Title": "Search",
            "Search": "search",
            "ResultsFor": "Results for",
            "Back": "back",
            "Next": "next",
            "List": "List",
            "Single": "Single",
            "PageAbbreviation": "Page",
            "Keyword": "Keyword",
            "KeywordTooShort": "The keyword must be at least 3 characters long",
        },
        "Attachment": {
            "Download": "Downloads",
            "Title": "Attachment",
            "AllCategories": "All categories",
            "AddedOn": "Added on",
            "ShowAllAttachments": "Show all downloads",
            "AlreadyDownloaded": "You already downloaded {{ name }}. Do you want to download it again?",
            "Open": "Open",
            "DownloadAgain": "Download again",
            "DownloadError": "Something went wrong",
            "NoAttachments": "There are no attachments for this book!"
        },
        "ImageModal": {
            "Image": "Image",
            "Back": "Back"
        },
        "Dialog": {
            "AlertMessage": "Warning! Your changes will be lost if you don’t save them.",
            "Cancel": "Cancel",
            "Save": "Save",
            "DoNotSave": "Don’t save",
            "Delete": "Delete",
            "OK": "OK",
            "SyncBeforeDelete": "You must synchronize the changes before deleting a book",
            "Close": "Close"
        },
        "Menu": {
            "shop": "Visit shop",
            "AboutLitello": "About Litello",
            "Credits": "Credits",
            "Changelog": "Changelog",
            "Imprint": "Imprint",
            "DataPrivacy": "Data Privacy",
            "Support": "Help and support",
        },
        "MenuTooltip": {
            "Edit": "Edit",
            "Locate": "Locate",
            "Delete": "Delete"
        },
        "Credits": {
            "Credits": "Credits",
            "Component": "Component",
            "Owner": "Owner",
            "License": "Licence",
            "NetworkMessage": "You need to have a network connection in order to see the credits."
        },
        "Changelog": {
            "Changelog": "Changelog"
        },
        "HelpAndSupport": {
            "Header": "Help und Support",
            "Title": " What functions are available to you ",
            "IntroText": " Here you will find help for the individual functions in Litello. However, if any questions remain unanswered or technical problems occur, please contact support.",
            "Bib": " Library ",
            "Search": "Search",
            "Marks": "Markings",
            "Downloads": "Downloads",
            "ContactSupport": "Contact support"
        },
        "Support": {
            "Header": "Contact support",
            "BackButton": " go back ",
            "Title": " Contact support",
            "IntroText": " Do you have problems with the application or a litelloBook? Then write to us. Please select the subject from the dropdowns and attach a short message roughly describing the problem.",
            "Email1": " Contact email address *",
            "Email2": " Confirm email address *",
            "EmailError1": " Please enter an email in the correct format",
            "EmailError2": " Please enter email",
            "EmailError3": " The emails must match!",
            "Subject": "Subject",
            "Subject1": " Problem with a LitelloBook",
            "Subject2": "Problem with the Webreader",
            "Subject3": " Other ",
            "SubjectError1": " Please select subject!",
            "SubjectValue": " Enter subject*",
            "Book": "Select book*",
            "SubjectValueError": " Please enter subject*",
            "BookError": " Please select book *",
            "Message": " Message*",
            "MessageError": " Please enter message",
            "Notice": "(In order to process your request, we need a contact email address. This will only be used to answer the request.)*",
            "SendButton": "Contact support",
            "emailPlaceholder": "Email",
            "subjectPlaceholder": "Select subject",
            "bookPlaceholder": "Select book",
            "subjectValuePlaceholder": "Reason"
        },
        "Help": {
            "HeaderMarks": "Help und Support | Markings",
            "HeaderSearch": "Help und Support | Search",
            "HeaderDownloads": "Help und Support | Downloads",
            "BackButton": " go back"
        },
        "DataPrivacy": {
            "Title": "Data Privacy"
        },

        "Imprint": {
            "Title": "Imprint"
        },
        "Settings": {
            "NetworkConnected": "Network connected",
            "NetworkDisconnected": "Network disconnected"
        },
        "OfflineMode": {
            "Download": "Download",
            "Open": "Open"
        },
        "InternetConnection": {
            "InOfflineMode": "No Internet Connection. You are now in Offline-Mode.",
            "NoInternetConnection": "No Internet connection.",
            "Online": "Online!"
        },
        "Toast": {
            "Close": "Click to Close",
            "Favorite": "Favorite",
            "Done": "Done"
        }
    }
};