<ion-header class="ion-modal-header">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="Tipp === 'search'" class="header-title ion-text-left" l10nTranslate> Help.HeaderSearch </ion-title>
    <ion-title *ngIf="Tipp === 'downloads'" class="header-title ion-text-left" l10nTranslate> Help.HeaderDownloads </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>

  <ion-grid >
    <ion-row>
      <ion-label (click)="goBack()" class="backButton" l10nTranslate> Help.BackButton</ion-label>
    </ion-row>
    <ion-row class="Content">
        <div *ngFor="let item of Tipps[Tipp].content" class="accordionElement">
          <div (click)="openAccordeon(item)" class="accordionButton">
            <ion-row>
              <ion-col sizeXs="10" sizeSm="10" sizeMd="10" sizeLg="10"><p>{{item.question}}</p></ion-col>
              <ion-col class="arrowContainer" sizeXs="2" sizeSm="2" sizeMd="2" sizeLg="2">
                <ion-icon *ngIf="!item.opened" class="Arrow" name="chevron-down-outline"></ion-icon> 
                <ion-icon *ngIf="item.opened" class="Arrow" name="chevron-up-outline"></ion-icon>
              </ion-col>
            </ion-row>
            </div>
          <div id={{item.id}} class="panel">
            <p>{{item.answer}}</p>
          </div>
        </div>
    </ion-row>
  </ion-grid>
</ion-content>
