import { Injectable } from '@angular/core';
import Epub, { Contents, EpubCFI, Rendition } from 'epubjs';
import Book from 'epubjs/types/book';
import { EpubCFIStep } from 'epubjs/types/epubcfi';
import { NavItem } from 'epubjs/types/navigation';
import { DisplayedLocation } from 'epubjs/types/rendition';
import Section, { SpineItem } from 'epubjs/types/section';
import * as rangy from 'rangy';
import { BehaviorSubject } from 'rxjs';
import { None, Option, Some } from 'space-lift';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { DeviceDetectorService } from '../../../util/device-detector/device-detector.service';
import { EPUB_AREA_ID } from '../../book/book.component';

export type SearchResult = {
  text: string
  href: string | undefined
  cfi: string | undefined
  page: number | undefined
  cfiDetails: string
  stepsToNode: EpubCFIStep[]
};

type EpubRenditionEventType =
  | 'mousedown'
  | 'mouseup'
  | 'keydown'
  | 'keyup'
  | 'touchstart'
  | 'touchend'
  | 'selected'
  | 'click'
  | 'markClicked'
  | 'displayed'
  | 'rendered'
  | 'relocated'
  | 'resized';

@Injectable()
export class EpubRenderService {
  private _rendition: Option<Rendition> = None;
  private _book: Option<Book> = None;
  private _flattenedChapters: NavItem[] = [];
  private _initialRootFontSize: number = 16;

  get currentPage(): number | undefined {
    return this._rendition?.get()?.location?.start?.index;
  }

  get currentCfi(): string | undefined {
    return this._rendition?.get()?.location?.start?.cfi;
  }

  public readonly DEFAULT_MAXZOOM: number = 10;
  public readonly DEFAULT_MINZOOM: number = -7;
  public textZoomLevel = new BehaviorSubject<number>(0);
  public setZoom(value: number) {
    if(
      value > this.DEFAULT_MAXZOOM ||
      value < this.DEFAULT_MINZOOM
    ) {
      value = 0;
    }

    this.textZoomLevel.next(value);
    this.updateFontSize(value);
  }

  constructor(
    private deviceDetector: DeviceDetectorService,
    private spinningService: SpinnerService
  ) {}

  public renderTo(
    renderAreaId: string,
    url: string,
    currentPageCallback?: (pageNumber: number) => void
  ): Promise<void> {
    if(this._rendition && this._rendition.get()) {
      this._rendition.get()!.destroy();
      this._rendition = None;
    }

    const book = Epub(url);
    const rendition = book.renderTo(
      renderAreaId,
      {
        spread: 'none',
        flow: 'scrolled-continuous',
        overflow: 'hidden',
        allowScriptedContent: true
      }
    );

    rendition.hooks.content.register(this.setContents.bind(this));
    rendition.book.spine.hooks.serialize.register(this.hideBook.bind(this));
    rendition.on('rendered', (section: Section) => {
      this.prepareTextZoom(section);
      this.showBook();
    });

    if(currentPageCallback) this.setLocationChangedCallback(rendition, currentPageCallback);

    this._rendition = Some(rendition);
    this._book = Some(book);
    this._flattenedChapters = [];
    return rendition.display();
  }

  private prepareTextZoom = async (section: Section) => {
    const iframeWrapper = document.getElementById(EPUB_AREA_ID)!;
    const contentDocument = iframeWrapper.querySelector('iframe')?.contentDocument;
    const rootElement = contentDocument?.body.parentElement;
    if(rootElement && contentDocument) {
      this.setOriginalFontSize(rootElement);
      this.updateFontSize(this.textZoomLevel.value, rootElement);
    }
  }

  private setContents = (contents: Contents, rendition: Rendition): void => {
    const iframeWrapper = document.getElementById(EPUB_AREA_ID)!;
    const iframeRootElement = contents.document.documentElement;
    this.hideVideoFullscreen();

    let minHeight = iframeWrapper.clientHeight;
    const placeholder = document.querySelector('.placeholder');
    if (placeholder) {
      const placeholderHeight = placeholder.clientHeight;
      minHeight = minHeight - placeholderHeight;
    }
    contents.addStylesheetRules(
      {
        body: {
          'max-width': this.deviceDetector.isDesktop() ? '60vw' : '100vw',
          margin: '0 auto !important',
          'padding-left': '16px!important',
          'padding-right': '16px!important',
        },

        '.main-chapter': {
          'min-height': minHeight + 'px',
        },
      },
      'litelloEPubReaderStyleDimensions'
    );
    iframeRootElement.addEventListener('contextmenu', (event) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    });
  }

  public hideVideoFullscreen() {
    const iframe = document.querySelector('iframe');
    if (iframe) {
      const contentDoc = iframe.contentDocument;
      if (contentDoc) {
        const hideStyle = '<style>video::-webkit-media-controls-fullscreen-button {display: none !important; }</style>';
        const head = contentDoc.getElementsByTagName('head')[0];
        head.innerHTML = head.innerHTML + hideStyle;
      }
    }
  }

  public async goToSubSection(hash: string) {
    if (hash === "#") {
      return;
    }
    const iframe = document.querySelector('iframe');
    if (iframe && hash) {
      const contentWindow = iframe.contentWindow;
      if (contentWindow) {
        try {
          const path = await this.getPathFromId(hash.split('#')[1]);
          await this.displayCfi(path.cfi, path.steps);
        }
        catch (error) {
          console.log("No valid subsection has been found for hash: " + hash);
        }
      }
    }
  }

  public async getScrollTop() {
    const ionContent: HTMLIonContentElement | null = document.querySelector(`#${EPUB_AREA_ID}`);
    const scrollElem = await ionContent!.getScrollElement();
    return scrollElem.scrollTop;
  }

  public async scrollTo(pos: number) {
    const ionContent: HTMLIonContentElement | null = document.querySelector(`#${EPUB_AREA_ID}`);
    const scrollElem = await ionContent!.getScrollElement();
    scrollElem.scrollTo(0, pos);
  }

  public async scroll(delta: number) {
    const ionContent: HTMLIonContentElement | null = document.querySelector(`#${EPUB_AREA_ID}`);
    const scrollElem = await ionContent!.getScrollElement();
    return scrollElem.scroll(0, scrollElem.scrollTop + delta);
  }

  public getSpine() {
    if (this._book.isDefined()) {
      return this._book.get().spine;
    }
  }

  async getChapters() {
    if (this._book.isDefined()) {
      return this._book.get().navigation.toc;
    }
  }

  async totalPages() {
    if (this._book.isDefined()) {
      const spine = <any>this._book.get().spine;
      return spine.length;
    }
  }

  public getChapterInformationForDiscussion() {
    if (this._book.isDefined() && this._rendition.isDefined()) {
      const locationCfi: any = this._rendition.get().currentLocation();
      const locationCfiStart = locationCfi.start.cfi;
      const spineItem = this.getLatestMainChapter(locationCfiStart);
      let selectedChapter = '';
      if (spineItem) {
        selectedChapter = spineItem.label.trim();
      }
      const chapterList: string[] = [];
      this._book.get().navigation.toc.forEach((chapter) => {
        chapterList.push(chapter.label.trim());
      });
      return {
        selectedChapter: selectedChapter,
        chapterList: chapterList,
      };
    }
  }

  public getFlattenedChapters() {
    if (this._book.isDefined()) {
      const toc = this._book.get().navigation.toc;
      toc.forEach((navItem: NavItem) => {
        this._flattenedChapters.push(navItem);
        this.flattenNavItem(navItem, this._flattenedChapters);
      });
    }
  }

  private flattenNavItem(navItem: NavItem, navItems: NavItem[]): void {
    if (navItem.subitems) {
      navItem.subitems.forEach((navItem: NavItem) => {
        navItems.push(navItem);
        this.flattenNavItem(navItem, navItems);
      });
    }
  }

  public getLatestMainChapter(cfi: string) {
    let chapter = this.getLatestChapter(cfi);
    if (chapter) {
      return this.getMainChapter(chapter);
    } else {
      if (this._book.isDefined()) {
        return this._book.get().navigation.toc[0];
      }
    }
  }

  private getMainChapter(navItem: NavItem): NavItem {
    if (this._book.isDefined() && navItem.parent) {
      const parentNavItem = this._flattenedChapters.find((chapter) => chapter.id === <any>navItem.parent);
      if (parentNavItem) {
        return this.getMainChapter(parentNavItem);
      }
    }
    return navItem;
  }

  public getLatestChapter(cfi: string): NavItem | undefined {
    if (this._book.isDefined()) {
      const spineItem = this._book.get().spine.get(cfi);
      if (spineItem) {
        const navItem = this._flattenedChapters.find((navItem: NavItem) => {
          return spineItem.href ? navItem.href.includes(spineItem.href) : false;
        });
        if (navItem) {
          return navItem;
        } else if (spineItem.prev()) {
          return this.getLatestChapterHelp(spineItem.prev());
        }
      }
    }
    return undefined;
  }

  public getLatestChapterHelp(spineItem: SpineItem): NavItem | undefined {
    const navItem = this._flattenedChapters.find((navItem: NavItem) => {
      return spineItem.href ? navItem.href.includes(spineItem.href) : false;
    });
    if (navItem) {
      return navItem;
    } else if (spineItem.prev()) {
      return this.getLatestChapterHelp(spineItem.prev());
    } else {
      return undefined;
    }
  }

  async getTextFromCfi(cfi: string) {
    if (this._book.isDefined()) {
      const book: any = this._book.get();
      return book
        .getRange(cfi)
        .then((result: string) => {
          return result;
        })
        .catch((err: any) => {
          let t;
          t = '';
          return undefined;
        })
        .finally(() => {});
    }
  }

  async getCfiFromHref(href: string) {
    if (this._book.isDefined()) {
      const id = href.split('#')[1];
      const book = this._book.get();
      const section = book.spine.get(href);
      if (section) {
        const document = section.load(book.load.bind(book));
        if (document) {
          const el = id ? section.document.getElementById(id) : section.document.body;
          if (el) {
            return section.cfiFromElement(el);
          }
        }
      }
    }
  }

  async getHrefFromCfi(cfi: string) {
    if (this._book.isDefined()) {
      const spineItem = this._book.get().spine.get(cfi);
      return spineItem.href;
    }
  }

  async getPageFromCfi(cfi: string): Promise<number | undefined> {
    if (this._book.isDefined()) {
      const page: any = this._book.get().spine.get(cfi);
      return page.index + 1;
    }
  }


  /**
   * Extracts the table of contents from the book.
   * This method has lots of workarounds since the epub.js library does not provide a way to extract the table of contents.
   * @returns {Promise<null | NavItem[]>} Returns the table of contents of the book or null if it is unable to extract it.
   */
  async getTOC(): Promise<null | NavItem[]> {
    if(!this._book.isDefined()) {
      return null;
    }

    const book = this._book.get();
    let tocId, spineItems;

    // This is a workaround, since epub.js does provide the spineItems but does not define them.
    try {
      //@ts-ignore
      spineItems = book.spine.spineItems;
    }
    catch {
      return null;
    }
    
    for (let item of spineItems) {
      if (item.href.startsWith('Inhaltsverzeichnis' || 'TableofContent')) {
        tocId = item.href;
        break;
      }
    }

    // Set up temporary html element to extract the TOC
    if (!tocId) {
      return null;
    }
    const section = book.spine.get(tocId);
    // !The following await is necessary, since the load method is asynchronous
    const html = await section.load(book.load.bind(book));
    if (!html) {
      return null;
    }
    let toc = html.querySelector('#TOC');
    if (!toc) {
      return null;
    }
    let tmpToc = toc.cloneNode(true);
    if (!tmpToc) {
      return null;
    }

    // Prepare the toc by removing the href and replacing it with an id
    // This is done to make the links work in the app
    try {
      // @ts-ignore
      let anchors = tmpToc.querySelectorAll('a');
      for (let anchor of anchors) {
        let href = anchor.getAttribute('href');
        anchor.removeAttribute('href');
        anchor.setAttribute('id', href);
      }
      // @ts-ignore
      return tmpToc.outerHTML;
    }
    catch {
      return null;
    }
  }

  public async displayCfi(target: string, steps?: EpubCFIStep[]): Promise<void> {
    if (!this._rendition.isDefined())
      return;
    
    return this._rendition
      .get()
      .display(target)
      .then(() => {
        const ionContent: HTMLIonContentElement | null = document.querySelector(`#${EPUB_AREA_ID}`);
        if (ionContent && EpubCFI.prototype.isCfiString(target)) {
          if(steps) {
            this.showHiddenElement(steps, ionContent);
          }
          const contentsArray: any = this._rendition.get()!.getContents();
          const contents: any = contentsArray[0];
          const top = contents.range(target).getBoundingClientRect().top - 50;
          ionContent.scrollToPoint(0, top, 200);
        } else if (ionContent) {
          ionContent.scrollToTop();
        }
      });
  }

  public async goToCfi(cfi: string) {
    if (this._rendition.isDefined()) {
      return this._rendition.get().display(cfi);
    }
  }

  public getCfiPosition(cfiRange: string): ClientRect | null {
    if (this._rendition.isDefined() && EpubCFI.prototype.isCfiString(cfiRange)) {
      const contentsArray: any = this._rendition.get().getContents();
      const contents: any = contentsArray[0];
      if (contents.range(cfiRange)) {
        const rects: DOMRectList = contents.range(cfiRange).getClientRects();
        return rects.item(rects.length - 1);
      }
    }
    return null;
  }

  public removeAllSelections() {
    const iframe = document.getElementsByTagName('iframe').item(0);
    if (iframe) {
      const contentDoc = iframe.contentDocument;
      if (contentDoc) {
        const selection = contentDoc.getSelection();
        if (selection) {
          selection.removeAllRanges();
        }
      }
    }
  }

  public stopRendering(): boolean {
    this._rendition.forEach((r) => r.destroy());
    this._rendition = None;
    return true;
  }

  public resize(width: number, height?: number) {
    this._rendition.forEach((r) => {
      r.resize(width, height!);
    });
  }

  public async getPathFromId(id: string): Promise<{cfi: string, steps: EpubCFIStep[]}> {
    return new Promise<{cfi: string, steps: EpubCFIStep[]}>((resolve, reject) => {
      let book = this._book.get();
      let foundNode = false;
      book?.spine.each((section: Section) => {
        // @ts-ignore - the load() method returns a Promise of HTMLElement (html root), not Document
        section.load().then(
          async (content: HTMLElement) => {
            let body = content.getElementsByTagName('body')[0];
            let range = rangy.createRange();
            range.selectNode(body);

            let textNodes = range.getNodes([Node.ELEMENT_NODE], (node) => {
              if (node instanceof HTMLElement && node.id === id) {
                return true;
              }
              return false;
            });

            if (textNodes.length > 0) {
              let textNode = textNodes[0];
              let cfi = await this.getCfiFromHref(section.href);
              if(!cfi) {
                reject(`No cfi found for href ${section.href}`);
                return;
              }
              let cfiNode = new EpubCFI(textNode);
              let cfiString = cfiNode.toString();
              cfi = cfi.substring(0, cfi.lastIndexOf('/4'));
              cfiString = cfiString.substring(cfiString.indexOf('4/'));

              // @ts-ignore this property does exist
              let path = cfiNode.path.steps;
              if(!path || path.length < 2) {
                reject(`No path found for node with id ${id}`);
                return;
              }

              resolve(
                {
                  cfi: cfi + cfiString,
                  steps: path
                }
              );
              foundNode = true;
            }
          }
        );
      });
      setTimeout(() => {
        if (!foundNode) {
          reject(`No node with id ${id} found`);
        }
      }, 5000);
    });
  }

  public async search(search_text: string): Promise<SearchResult[]> {
    let results: SearchResult[] = [];
    if (search_text && this._book && this._book.isDefined()) {
      let book = this._book.get();
      book.spine.each(
        (section: Section) => {
          // @ts-ignore - the load() method returns a Promise of HTMLElement (html root), not Document
          section.load().then(
            async (content: HTMLElement) => {
              let cfi: string | undefined;
              let page: number | undefined;
              let body = content.getElementsByTagName('body')[0];
              let range = rangy.createRange();
              range.selectNode(body);
              let textNodes = range.getNodes([Node.TEXT_NODE], (node) => {
                let regExp = new RegExp(search_text, 'i');
                return regExp.test((node as Text).data);
              });

              cfi = await this.getCfiFromHref(section.href);
              if(cfi) page = await this.getPageFromCfi(cfi);

              textNodes.forEach((textNode) => {
                if (!textNode.textContent) return;

                let cfiNode = new EpubCFI(textNode);
                let cfiString = cfiNode.toString();
                let pattern = new RegExp(search_text, 'gi');

                let foundItem: SearchResult = {
                  text: textNode.textContent.replace(pattern, '<b>' + '$&' + '</b>'),
                  href: section.href,
                  page,
                  cfi,
                  cfiDetails: cfiString,
                  // @ts-ignore this property does exist
                  stepsToNode: cfiNode.path?.steps
                };
                results.push(foundItem);
              });
            }
          );
        }
      );
    } else {
      console.error('Search text or book is not defined!');
      console.error('search_text: ', search_text, ', book: ', this._book);
    }
    return results;
  }

  public async goTo(page: number): Promise<void> {
    return this._rendition.map((r) => r.display(page)).getOrElse(Promise.resolve());
  }

  public prev(): Promise<void> {
    const ionContent: HTMLIonContentElement | null = document.querySelector(`#${EPUB_AREA_ID}`);
    if (ionContent) {
      ionContent.scrollToTop();
    }
    return this._rendition.map((r) => r.prev()).getOrElse(Promise.resolve());
  }

  public next(): Promise<void> {
    const ionContent: HTMLIonContentElement | null = document.querySelector(`#${EPUB_AREA_ID}`);
    if (ionContent) {
      ionContent.scrollToTop();
    }
    return this._rendition.map((r) => r.next()).getOrElse(Promise.resolve());
  }

  public attachCallback(
    event: EpubRenditionEventType,
    listener: (...e: any) => void
  ): void {
    this._rendition.forEach((r) => r.on(event, listener));
  }

  public detachCallback(event: EpubRenditionEventType, listener: Function): void {
    this._rendition.forEach((r) => r.off(event, listener));
  }

  private setLocationChangedCallback(rendition: Rendition, currentPageCallback: (pageNumber: number) => void) {
    rendition.on('locationChanged', (location: DisplayedLocation) => {
      currentPageCallback(location.index);
    });
  }

  public changeSelectionColor(color: string): void {
    let colorString: string = color;

    if (this._rendition.isDefined()) {
      const contents: any = this._rendition.get().getContents();
      contents.forEach((c: Contents) =>
        c.addStylesheetRules(
          {
            '::selection': {
              background: colorString,
            },
          },
          'selectionColor'
        )
      );

      this._rendition.get().hooks.content.register(() => {
        if (this._rendition.isDefined()) {
          const contents: any = this._rendition.get().getContents();
          contents.forEach((c: Contents) =>
            c.addStylesheetRules(
              {
                '::selection': {
                  background: colorString,
                },
              },
              'selectionColor'
            )
          );
        }
      });
    }
  }

  /**
   * Reads and stores the font-size style property of the root HTML element of the displayed page.
   * @param {Rendition} rendition
   * 
   * Also applies REM font-size to all book elements. This functionality should be deleted, and the styles with REM sizes implemented in the books' style files.
   * @see {@link convertAllElementsInlineFontSizesToRemUnits}
   */
  private setOriginalFontSize = (rootElement: HTMLElement) => {
    const rootElementFontSize: string = window.getComputedStyle(rootElement).fontSize;
    this._initialRootFontSize = rootElementFontSize ? Number.parseFloat(rootElementFontSize) : 16;
  }

  /**
   * Sets font-size property of the book's root HTML element.
   * @param {number} zoomLevel - integer from -10 to 10
   */
   private updateFontSize = (zoomLevel: number, rootElement?: HTMLElement) => {
    if(!rootElement) {
      const contents = <Array<Contents> | undefined>this._rendition.get()?.getContents();
      rootElement = <HTMLElement | undefined>contents?.[0]?.documentElement;
    }
    if(rootElement) {
      rootElement.style.fontSize = `${this._initialRootFontSize + zoomLevel}px`;
    }  
  }

  private hideBook() {
    const iframeWrapper = document.getElementById(EPUB_AREA_ID)!;
    iframeWrapper.style.opacity = '0';
    iframeWrapper.style.visibility = 'hidden';
  }

  public showBook = async () => {
    const iframeWrapper = document.getElementById(EPUB_AREA_ID)!;
    iframeWrapper.style.opacity = '1';
    iframeWrapper.style.visibility = 'visible';
    await this.spinningService.hideSpinner();
  }

  private showHiddenElement(steps: EpubCFIStep[], ionContent: HTMLIonContentElement) {
    const iframeRootElement = ionContent.querySelector('iframe')?.contentDocument?.documentElement;
    if(!iframeRootElement)
      return;

    let currentElement: HTMLElement | null = iframeRootElement;
    steps.forEach(step => {
      if(currentElement) {
        if(window.getComputedStyle(currentElement).display === 'none') currentElement.style.display = 'unset';
        if(window.getComputedStyle(currentElement).visibility === 'hidden') currentElement.style.visibility = 'visible';
        currentElement = <HTMLElement>currentElement?.children?.item(step.index);
      }
    });
  }

  // Extracts the search string from the search result
  private extractSearchString(searchText: string): string {
    const regex = /<b>(.*?)<\/b>/;
    const matches = searchText.match(regex);
    return matches ? matches[1] : "";
  }

  /**
   * Scroll to the target element and highlight the search result
   * @param {string} target - The target cfi
   * @param {EpubCFIStep[]} steps - The steps to the target element
   * @param {string} text - The text to display in the search result
   * @returns {void}
  **/
  public async gotoSearchResult(target: string, steps: EpubCFIStep[], text: string): Promise<void> {
    if(!this._rendition.isDefined())
      return;

    await this.displayCfi(target, steps);

    const ionContent: HTMLIonContentElement | null = document.querySelector(`#${EPUB_AREA_ID}`);
    if(!ionContent)
      return;

    const iframeRootElement = ionContent.querySelector('iframe')?.contentDocument?.documentElement;
    if (!iframeRootElement)
      return;

    const highlightText = this.extractSearchString(text);
    const searchTargetRegex = new RegExp(highlightText, 'gi');

    // Get the element that contains target string
    let currentElement: HTMLElement | null = iframeRootElement;
    steps.forEach((step) => {
      // If the element contains the target string, set it as the current element
      // We do this to avoid getting empty elements like <br>, which might be the last element in the steps array
      if (currentElement && currentElement.children.item(step.index)?.textContent?.match(searchTargetRegex)) {
        currentElement = <HTMLElement>currentElement.children.item(step.index);
      }
    });

    /* 
     * If the element does not contain the search-target class, add it
     * We use the mark tag because span tag messes up the layout
     * The style attributes are used to override the mark tag's default styles
    **/
    if (!currentElement.classList.contains('search-target') && !currentElement.innerHTML?.match(/search-target/gi))
      currentElement.innerHTML = currentElement.innerHTML.replace(searchTargetRegex,"<mark class='search-target' style='background-color: inherit; color:inherit;'>" + highlightText + "</mark>");

    // Animate all search-target elements
    const searchTargetElements = Array.from(iframeRootElement.getElementsByClassName('search-target'));
    searchTargetElements.forEach(element => {
      element.animate([
        { backgroundColor: "rgba(255, 5, 5, 0.5)" },
        { backgroundColor: "rgba(255, 5, 5, 0.5)" },
        { backgroundColor: "transparent" },
      ],
      {
        duration: 1000,
        iterations: 1,
      }).onfinish = () => {
        element.outerHTML = element.innerHTML;
      };
    });
  }
}
