import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from '../../../util/device-detector/device-detector.service';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewRef } from '@angular/core';
import { ClientBook } from '../../../PODO/clientBook';
import { ModalController } from '@ionic/angular';
import { ContactSupportComponent } from '../../../components/contact-support/contact-support.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-book-header',
  templateUrl: './book-header.component.html',
  styleUrls: ['./book-header.component.scss'],
})
export class BookHeaderComponent implements OnInit, OnDestroy {
  innerWidth: any;

  @Input() zoom: number = 0;
  @Output() zoomIn = new EventEmitter<boolean>();
  @Output() zoomOut = new EventEmitter<boolean>();

  @Input() previousPageButton: boolean = false;
  @Output() onNavigate = new EventEmitter<any>();

  @Input() bookCoverSrc: string | undefined;
  @Input() bookTitle: string | undefined;
  @Input() tableOfContentsOpened: boolean | undefined;
  @Input() book: ClientBook | undefined;
  @Input() selectedChapter: string = '';
  @Output() onMobileWidth = new EventEmitter<any>();
  @Output() onToggleTableOfContents = new EventEmitter<any>();
  @Output() openBookLibrary = new EventEmitter<any>();
  @Output() onToggleSearch = new EventEmitter<any>();

  public noteIcon = false;
  public activatedView = 'book';

  private subscriptions: Subscription[] = [];

  public contrastColor = getComputedStyle(document.documentElement).getPropertyValue('--book-contextMenu-color4');

  constructor(
    public sanitizer: DomSanitizer,
    public deviceDetector: DeviceDetectorService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  onZoomIn(event: any) {
    this.zoomIn.emit(event);
  }
  
  onZoomOut(event: any) {
    this.zoomOut.emit(event);
  }

  goToBookLibrary() {
    this.openBookLibrary.emit(true);
  }

  public async openContactSupport() {
    let cssClass = this.deviceDetector.isDesktop() ? 'ion-main-modal' : 'ion-main-modal-mobile';
    const popover = await this.modalCtrl.create({
      component: ContactSupportComponent,
      cssClass: cssClass,
    });
    return await popover.present();
  }

  tableOfContentView() {
    this.onToggleTableOfContents.emit(!this.tableOfContentsOpened);
  }

  goToPrevPage() {
    this.onNavigate.emit();
  }

  public notifyChanges() {
    if (!(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }
  }
}
