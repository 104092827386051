import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule } from '@auth0/angular-jwt';
import { Dialogs } from '@awesome-cordova-plugins/dialogs/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { IonicModule } from '@ionic/angular';
import * as Sentry from '@sentry/angular-ivy';
import { L10nIntlModule, L10nLoader, L10nTranslationModule } from 'angular-l10n';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './barrel.module';
import { ComponentsModule } from './components/components.module';
import { AuthGuardService } from './guards/authGuard.service';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { Zip } from '@awesome-cordova-plugins/zip/ngx';
import { SQLitePorter } from '@awesome-cordova-plugins/sqlite-porter/ngx';
import { BookModule } from './book/book.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FormsModule } from '@angular/forms';
import { l10nConfig } from '../assets/localization/l10n-config';

export function tokenGetter(): string|null {
  return localStorage.getItem('token');
}

let langCode = 'de';
let countryCode = 'DE';
let chosenLocale = localStorage.getItem('locale');
let browserLang = navigator.language;
if (chosenLocale) {
  let locales = chosenLocale.split('-');
  langCode = locales[0];
  countryCode = locales[1];
} else if (browserLang === 'en' || browserLang === 'en-GB' || browserLang === 'en-US') {
  localStorage.setItem('defaultLocale', 'en-GB');
} else {
  localStorage.setItem('defaultLocale', 'de-DE');
}

// For date pipe translation
registerLocaleData(localeDe);
registerLocaleData(localeEn);

@NgModule({
    declarations: [AppComponent],
    imports: [
        FormsModule,
        MaterialModule,
        BrowserAnimationsModule,
        HttpClientModule,
        IonicModule.forRoot(),
        L10nTranslationModule.forRoot(l10nConfig),
        L10nIntlModule,
        ComponentsModule,
        AppRoutingModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ['api.litello.com'],
            },
        }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        BookModule,
        NgCircleProgressModule.forRoot({
            showSubtitle: false,
            titleFontSize: '10',
            radius: 15,
            outerStrokeWidth: 0,
            innerStrokeWidth: 0,
            outerStrokeColor: '#78C000',
            backgroundColor: 'gray',
            titleColor: 'white',
            unitsColor: 'white',
            backgroundPadding: 0,
            animation: true,
            animationDuration: 300,
            maxPercent: 100,
        }),
    ],
    providers: [
        SQLitePorter,
        Zip,
        FileOpener,
        InAppBrowser,
        Dialogs,
        SQLite,
        AuthGuardService,
        DatePipe,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false, // Disable show report dialog
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
            useValue: undefined,
        },
        SQLite,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public l10nLoader: L10nLoader) {
  }
}
