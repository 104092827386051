import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Constants } from './PODO/constants';
import { SelectivePreloadingStrategyService } from './services/selective-preloading-strategy/selective-preloading-strategy.service';
import { ThemeInitializationGuard } from './guards/theme-initialization.guard';
import { BookComponent } from './book/book/book.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [ThemeInitializationGuard],
    component: BookComponent,
  },
  {
    path: Constants.URL.BookIdNavigation,
    component: BookComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: SelectivePreloadingStrategyService
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
