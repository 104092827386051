import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Constants } from '../PODO/constants';

@Injectable()
export class AuthGuardService  {
  constructor(private router: Router, private jwtHelper: JwtHelperService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): UrlTree | boolean {
    if (this.jwtHelper.isTokenExpired()) {
      return this.router.createUrlTree([Constants.URL.Authentication]);
    } else {
      return true;
    }
  }
}
