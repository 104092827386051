export const TIPPS_DE = {

        search : {
            title: 'Suche',
            content: [
                {
                    id: '1',
                    question: 'Wo finde ich die Suche?',
                    answer: 'Die Suche befindet sich auf der rechten Seite in der Toolbar.'
                },
                {
                    id: '2',
                    question: 'Was kann ich durchsuchen?',
                    answer: 'Sie können den gesamten Inhalt des litelloBooks durchsuchen. (Technisch ausgeschlossen sind derzeit Inhalte in Quizzen & Akkordeons.)'
                },
                {
                    id: '3',
                    question: 'Wie springe ich an die richtige Stelle im litelloBook?',
                    answer: 'Damit Sie die richtige Stelle im litelloBook finden, klicken Sie die Pinnadel unter dem Suchergebnis.'
                }
            ]
    },
    downloads : {
        title: 'Downloads',
        content: [
            {
                id: '1',
                question: 'Wo finde ich die angehängten Downloads?',
                answer: 'Sofern im geöffneten litelloBook Downloads vorhanden sind, sehen Sie in der Toolbar auf der rechten Seite eine Büroklammer. Dort haben Sie einen Überblick über alle angehängten Downloads. Sie finden die Downloads außerdem innerhalb des litelloBooks an den passenden Stellen.'
            },
            {
                id: '2',
                question: 'Wie funktionieren die Downloads?',
                answer: 'Sie können die Dateien direkt herunterladen. Achtung: Es wird nicht überprüft ob Sie den Download bereits getätigt haben.'
            }
        ]
    }
}

export const TIPPS_EN = {

        search : {
            title: 'Search',
            content: [
                {
                    id: '1',
                    question: 'Where can I find the search?',
                    answer: 'The search is located on the right side in the toolbar.'
                },
                {
                    id: '2',
                    question: 'What can I search?',
                    answer: 'You can search the entire content of the litelloBook. (Technically excluded are currently contents in quizzes & accordions).'
                },
                {
                    id: '3',
                    question: 'How do I jump to the right place in litelloBook?',
                    answer: 'To help you find the right place in litelloBook, click the pin under the search result.'
                }
            ]
        },
        downloads : {
            title: 'Downloads',
            content: [
                {
                    id: '1',
                    question: 'Where can I find the attached downloads?',
                    answer: 'If there are downloads in the opened litelloBook, you will see a paper clip in the toolbar on the right side. There you have an overview of all attached downloads. You will also find the downloads within the litelloBook at the appropriate places.'
                },
                {
                    id: '2',
                    question: 'How do the downloads work?',
                    answer: 'You can download the files directly. Attention: It will not be checked if you have already made the download.'
                }
            ]
        }
}