import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ModalController, Platform } from '@ionic/angular';
import * as Sentry from '@sentry/angular-ivy';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { ThemeService } from './services/themes/theme.service';
import { DeviceDetectorService } from './util/device-detector/device-detector.service';
import { StorageService } from './services/storage/storage.service';
import { Network } from '@capacitor/network';
import { NetworkChangedComponent } from './components/network-changed/network-changed.component';
import { L10N_LOCALE, L10nLocale, L10N_CONFIG, L10nConfig, L10nSchema } from 'angular-l10n';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  schema: L10nSchema[];
  public appPages = [
    {
      title: 'Books.Title',
      url: '/books',
      icon: 'chevron-forward-outline',
    },
  ];
  public browser: Observable<InAppBrowserObject> = EMPTY;
  public version: string = '';
  public isMenuOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isAuthPage: boolean = true;
  rootPage: any;
  networkStatus: boolean | undefined = undefined;

  constructor(
    public platform: Platform,
    private router: Router,
    private themeService: ThemeService,
    private deviceDetector: DeviceDetectorService,
    private storageService: StorageService,
    public modalController: ModalController,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig
  ) {
    this.schema = this.l10nConfig.schema;

    // TODO: Auth is no longer required and should be removed
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isAuthPage =
        event.url.includes('verify') || event.url.includes('activate') || event.url.includes('authentication') || event.urlAfterRedirects.includes('authentication');
      }
    })

    try {
      this.initializeApp();
    } catch (e: any) {
      Sentry.captureException(e);
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  initializeApp() {
    this.platform.ready().then(() => {
      if (!this.deviceDetector.isDesktop()) {
        this.storageService.getPreferences('is_first_time_run').then((res) => {
          if (!res) {
            this.storageService.savePreferences(`is_first_time_run`, 'true').then(() => {
            });
          }
        });
      }
      this.themeService.loadTheme();
    });

    Network.getStatus().then(async (status) => {
      if (!status.connected && this.deviceDetector.isDesktop()) {
        await this.presentModal(true, status.connected);
      } else if (!status.connected && !this.deviceDetector.isDesktop()) {
        await this.presentModal(false, status.connected);
      }
    });

    Network.addListener('networkStatusChange', async (status) => {
      const statusChanged = status.connected != this.networkStatus;
      if (statusChanged && this.deviceDetector.isDesktop()) {
        await this.presentModal(true, status.connected);
      } else if (statusChanged) {
        await this.presentModal(false, status.connected);
      }
      if (status.connected) {
        this.themeService.loadTheme();
      }
      this.networkStatus = status.connected;
    });
  }

  async presentModal(isDesktop: boolean, status: boolean) {
    this.modalController.dismiss(undefined, undefined, 'network');
    const modal = await this.modalController.create({
      id: 'network',
      component: NetworkChangedComponent,
      cssClass: 'global-network-modal',
      componentProps: {
        isDesktop: isDesktop,
        status: status,
      },
    });
    setTimeout(() => {
      modal.dismiss();
    }, 5000);
    return await modal.present();
  }

  redirect() {
    let storeLink = '';
    let appLink = 'litello://';
    if (this.deviceDetector.isAndroid()) {
      storeLink = `https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.litello.team.reader&ddl=1&pcampaignid-web_ddl_1`;
    } else if (this.deviceDetector.isIOS()) {
      storeLink = `itms-apps://itunes.apple.com/app/1478259463`;
    }

    this.openApp(appLink, storeLink);
  }

  openApp(url: string, fallbackUrl: string) {
    window.location.href = fallbackUrl;
  }

  fallbackToDownload(url: string) {
    window.location.replace(url);
  }

  public menuToggleHandler(opened: boolean) {
    this.isMenuOpened.next(opened);
  }
}
