import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError as _observableThrow } from 'rxjs';
import { ClientBook } from '../../PODO/clientBook';
import { environment } from '../../../environments/environment';

const AUTHORIZATION: string = "Authorization";

export interface ISyncService {
  /**
   * @param userId
   * @param customerName
   * @return 200 response
   */
  getThemes(customerName: string): Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class RestService implements ISyncService {
  private http: HttpClient;
  private baseUrl: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
  ) {
    this.http = http;
    // Set base url to production or development depending if enviroment.enviroment is production or not
    this.baseUrl = environment.production ? 'https://api.litello.com' : 'https://api-staging.litello.com';
  }

  /**
   * Downloads a file from the given url
   * @param url Url to download the file from
   * @returns Observable<HttpEvent<Blob>> Observable with the file
   */
  public downloadFile(url: any): Observable<HttpEvent<Blob>> {
    return this.http.get(url, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events'
    });
  }

  /**
   * Send analytics data to the server
   * Automatically adds 5 minutes to the closedAt timestamp
   * @param bookId Id of opened book
   * @returns Observable<any> Observable with an event id that is used to close the book
   */
  public analyticsOpenBook(bookId: number, timestamp: string): Observable<any> {
    const url = `${this.baseUrl}/lite/analytics/book`;
    const body = {
      "openedBook": {
        "bookId": bookId,
        "openedAt": timestamp
      }
    };
    return this.http.post(url, body);
  }

  /**
   * Send closed book event to the server for analytics
   * Sets the closedAt timestamp to the current time
   * @param eventId Id of the opened book
   * @returns Observable<any> Observable with the response
   */
  public analyticsCloseBook(eventId: number, timestamp: string): Observable<any> {
    const url = `${this.baseUrl}/lite/analytics/book`;
    const body = {
      "closedBook": {
        "id": eventId,
        "closedAt": timestamp
      }
    };
    return this.http.post(url, body);
  }

  /**
   * Gets theme data for a customer
   * @param customername Name of the customer to get theme data for
   * @returns Observable<any> Theme data
   */
  getThemes(customername: string): Observable<any> {
    if (!customername) throw new Error("The parameter 'customername' must be defined.");
    const url = `${this.baseUrl}/lite/themes/${customername}`;
    return this.http.get(url);
  }

  /**
   * Post a customer support message
   * TODO: Endpoint needs to be unauthenticated but it currently is not
   * @returns Observable<any>
   */
  postCSMessage(message: any): Observable<any> {
    const url = `${this.baseUrl}/email/support`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.post(url, message, {headers});
  }


  /**
   * Gets an array of credits, which are displayed in the credits section of the app
   * @returns Observable<any> List of credits
   */
  credits(): Observable<any> {
    const url = `${this.baseUrl}/credits`;

    return this.http.get(url);
  }
}

@Injectable({
  providedIn: 'root',
})
export class BooksService {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(
    @Inject(HttpClient) http: HttpClient,
  ) {
    this.http = http;
    this.baseUrl = environment.production ? 'https://api.litello.com' : 'https://api-staging.litello.com';
  }

  /**
   * Gets a book by id
   * @param bookid Id of the book to get
   * @returns Observable<ClientBook>
   */
  bookGet(bookid: number): Observable<ClientBook> {
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/lite/books/${bookid}`;
    return this.http.get<ClientBook>(url);
  }

  /**
   * Get attachments for a book
   * @param bookId Id of the book to get attachments for
   * @returns Observable<Attachment[]>
   */
  getAttachments(bookId: number): Observable<Attachment[]> {
    if (!bookId) throw new Error("The parameter 'bookId' must be defined.");
    const url = `${this.baseUrl}/lite/books/${bookId}/attachments`;
    return this.http.get<Attachment[]>(url);
  }

  /**
   * Get collections for a book
   * @param bookId Id of the book to get collections for
   * @returns Observable<Collection[]>
   */
  getCollections(bookId: number): Observable<Collections> {
    if (!bookId) throw new Error("The parameter 'bookId' must be defined.");
    const url = `${this.baseUrl}/lite/books/${bookId}/collections`;
    return this.http.get<Collections>(url);
  }
}

/**
 * Credit object that is displayed in the credits section
 */
export interface Credit {
  id: number | undefined;
  component: string | undefined;
  owner: string | undefined;
  license: string | undefined;
  link: string | undefined;
}

/**
 * Attachment object that is used by the attachment service
 */
export interface Attachment {
  id: number;
  filename: string;
  description: string;
  created: string;
  type: string;
  size: number;
  url: string;
  percent?: number;
}

export type Collections = {
  [collectionId: number]: ClientBook[]
}
