import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { marked } from 'marked';
import { DeviceDetectorService } from '../../util/device-detector/device-detector.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrls: ['./data-privacy.component.scss'],
})
export class DataPrivacyComponent implements OnInit {
  public loading: boolean = false;

  public dataPrivacyHtml: any;

  constructor(
    private modalCtrl: ModalController,
    private http: HttpClient,
    public deviceDetector: DeviceDetectorService,
    private translationService: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    this.loadDataPrivacy();
  }

  public refresh(event: any) {
    this.loadDataPrivacy();
    event.target.complete();
  }
  
  private loadDataPrivacy() {
    this.loading = true;
    const locale = this.getLanguageCode();

    this.getDataPrivacyData(locale).subscribe({
      next: (imprint) => {
        this.dataPrivacyHtml = marked.parse(imprint);
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

   /**
   * Gets the url to the data privacy markdown file.
   * All locales except "de" load the english version.
   * @param lang language code. (two letter code, e.g. "de")
   * @returns url to the markdown file as an observable.
   */
  private getDataPrivacyData(lang: string): Observable<any> {
    let url = lang === 'de' ? 'assets/DATAPRIVACY.md' : 'assets/DATAPRIVACY_EN.md';
    return this.http.get(url, { responseType: 'text' });
  }

   /**
   * Returns the language code of the current locale.
   * @returns {string} The language code of the current locale. (de, en, fr, etc.)
   */
   private getLanguageCode(): string {
    return this.translationService.getLocale().language;
  }

  public async closeDataPrivacy() {
    await this.modalCtrl.dismiss();
  }
}
