import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public async savePreferences(k: string, v: string) {
    await Preferences.set({
      key: k,
      value: v,
    });
  }

  public async getPreferences(k: string) {
    const { value } = await Preferences.get({ key: k });
    return value;
  }

  public async removePreferences(k: string) {
    await Preferences.remove({ key: k });
  }
}
