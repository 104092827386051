<ion-split-pane when="never" contentId="main-content">
  <ion-menu [disabled]="isAuthPage" menu-id="main-menu" contentId="main-content" (ionDidOpen)="menuOpened()" (ionDidClose)="menuClosed()">
    <ion-content color="white">
      <ion-list lines="none">
        <ion-menu-toggle auto-hide="false">
          <ion-item color="white" class="header">
            <img src="../../../assets/icon/lit-logo.png" alt="Litello" />
          </ion-item>
          
          <app-language-selection></app-language-selection>

          <div class="section bottom">
            <ion-item *ngIf="themeService.modul.visitShop === 1" (click)="browse('litello')" color="white">
              <ion-label l10nTranslate class="regular" color="medium"> Menu.AboutLitello </ion-label>
            </ion-item>
            <ion-item *ngIf="themeService.modul.changelog === 1" (click)="openChangelog()" color="white">
              <ion-label l10nTranslate class="regular" color="medium"> Menu.Changelog </ion-label>
            </ion-item>

            <ion-item *ngIf="themeService.modul.credits === 1" (click)="openCredits()" color="white">
              <ion-label l10nTranslate class="regular" color="medium"> Menu.Credits </ion-label>
            </ion-item>

            <ion-item *ngIf="themeService.modul.contactSupport === 1" (click)="openContactSupport()" color="white">
              <ion-label l10nTranslate class="regular" color="medium"> Menu.Support </ion-label>
            </ion-item>

            <ion-item
              (click)="browse('impressum')"
              color="white"
              *ngIf="isDesktop && themeService.modul.legalNote === 1"
            >
              <ion-label l10nTranslate class="regular" color="medium"> Menu.Imprint </ion-label>
            </ion-item>

            <ion-item (click)="browse('datenschutz')" color="white" *ngIf="isDesktop">
              <ion-label l10nTranslate class="regular" color="medium"> Menu.DataPrivacy </ion-label>
            </ion-item>

            <ion-item (click)="openImprint()" color="white" *ngIf="isNative">
              <ion-label l10nTranslate class="regular" color="medium"> Menu.Imprint </ion-label>
            </ion-item>

            <ion-item (click)="openDataPrivacy()" color="white" *ngIf="isNative">
              <ion-label l10nTranslate class="regular" color="medium"> Menu.DataPrivacy </ion-label>
            </ion-item>

          </div>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
    <ion-item color="white">
      <div class="copyright-version">
        <div>©{{ currentYear }} Litello GmbH</div>
        <div>Version: {{ version }}</div>
      </div>
    </ion-item>
  </ion-menu>
  <ion-router-outlet id="main-content" animated="false"></ion-router-outlet>
</ion-split-pane>
