import { ClientBook } from './../../PODO/clientBook';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { BooksService as BooksApiClient } from '.././rest-client/rest-client.service';

@Injectable({
  providedIn: 'root',
})
export class BooksService {
  booksList: ClientBook[] = [];

  public get books$() {
    return this._books$;
  }

  public networkStatus: any;

  private readonly _books$: BehaviorSubject<any[]>;

  constructor(
    private booksApiClient: BooksApiClient,
  ) {
    this._books$ = new BehaviorSubject<any>([]);
  }

  /**
   * Fetches a ClientBook from the API
   * @param bookId ID of the book to fetch
   * @returns Promise<ClientBook|undefined> Returns the book if found, undefined otherwise
   */
  async loadBook(bookId: number): Promise<ClientBook> {
    console.log('Loading book: ', bookId);
    const book = await firstValueFrom(this.booksApiClient.bookGet(bookId));
    return book;
  }
}
