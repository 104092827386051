export const EXCLUDED_COLOR_KEYS: string[] = ['id'];
export const GENERAL_COLOR_KEYS = [
  'primary',
  'primary-rgb',
  'primary-contrast',
  'primary-contrast-rgb',
  'primary-shade',
  'primary-tint',
  'secondary',
  'secondary-rgb',
  'secondary-contrast',
  'secondary-contrast-rgb',
  'secondary-shade',
  'secondary-tint',
  'tertiary',
  'tertiary-rgb',
  'tertiary-contrast',
  'tertiary-contrast-rgb',
  'tertiary-shade',
  'tertiary-tint',
  'success',
  'success-rgb',
  'success-contrast',
  'success-contrast-rgb',
  'success-shade',
  'success-tint',
  'warning',
  'warning-rgb',
  'warning-contrast',
  'warning-contrast-rgb',
  'warning-shade',
  'warning-tint',
  'danger',
  'danger-rgb',
  'danger-contrast',
  'danger-contrast-rgb',
  'danger-shade',
  'danger-tint',
  'dark',
  'dark-rgb',
  'dark-contrast',
  'dark-contrast-rgb',
  'dark-shade',
  'dark-tint',
  'medium',
  'medium-rgb',
  'medium-contrast',
  'medium-contrast-rgb',
  'medium-shade',
  'medium-tint',
  'light',
  'light-rgb',
  'light-contrast',
  'light-contrast-rgb',
  'light-shade',
  'light-tint',
];

export const COLOR_KEYS = [
  '--login-form-background1',
  '--login-background-color1',
  '--login-background-color2',
  '--login-form-login-button',
  '--login-form-login-button-text-color',
  '--login-form-registration-button',
  '--login-form-registration-button-text-color',
  '--login-text-color',
  '--registration-background-color1',
  '--registration-background-color2',
  '--registration-form-background1',
  '--registration-form-registration-button',
  '--registration-form-registration-button-text-color',
  '--registration-text-color',
  '--forgot-password-background-color1',
  '--forgot-password-background-color2',
  '--forgot-password-form-background1',
  '--forgot-password-form-back-button',
  '--forgot-password-form-back-button-text-color',
  '--forgot-password-form-send-button',
  '--forgot-password-form-send-button-text-color',
  '--forgot-password-text-color',
  '--footer-background-color',
  '--footer-font-color',
  '--dashboard-top-background',
  '--book-sidebar-icon-active',
  '--book-sidebar-icon-inactive',
  '--book-header-dashboard-button-color',
  '--book-header-toc-button-color',
  '--book-header-toc-button-font-color',
  '--book-sidebar-color',
  '--book-contextMenu-background',
  '--book-contextMenu-fontColor',
  '--dashboard-background-color',
  '--dashboard-intro-backgroundcolor1',
  '--dashboard-intro-backgroundcolor2',
  '--dashboard-intro-backgroundcolor3',
  '--dashboard-teaser-backgroundcolor1',
  '--ion-color-primary',
  '--dashboard-header-background-color',
  '--dashboard-headline-text-color',
  '--dashboard-intro-text-color',
  '--dashboard-eBook-headline-color',
  '--dashboard-teaser-backgroundcolor',
  '--dashboard-teaser-title-color',
  '--dashboard-teaser-author-color',
  '--dashboard-teaser-text-color',
  '--dashboard-teaser-button-color',
  '--dashboard-footer-text-color',
  '--book-contextMenu-color1',
  '--book-contextMenu-color2',
  '--book-contextMenu-color3',
  '--book-contextMenu-color4',
  '--highlight-color-1-opacity',
  '--highlight-color-2-opacity',
  '--highlight-color-3-opacity',
  '--highlight-color-4-opacity',
  '--book-sidebar-search-icon-color',
  '--book-sidebar-note-icon-color',
  '--book-header-title-color',
  '--book-toc-button',
  '--book-toc-button-text-color',
  '--book-toc-icon-color',
  '--book-sidebar-menu-header',
  '--book-sidebar-menu-header-title-color',
  '--book-sidebar-menu-search-header-icon-color',
  '--book-sidebar-menu-search-button',
  '--book-sidebar-menu-search-button-text-color',
  '--book-sidebar-menu-note-header-icon-color',
  '--book-attachment-item-hover',
  '--book-attachment-item-selected',
  '--book-attachment-name',
  '--book-attachment-description',
  '--lang-selection-font-color',
  '--book-header-mobile-border',
  '--book-contextMenu-divider',
  '--nov-border',
  '--nov-header-text',
  '--nov-info-text',
  '--nov-content-text',
  '--nov-checkboc-selected',
  '--nov-chapter-name-content-title',
  '--create-note-borders',
  '--create-note-info-text',
  '--create-note-content-text',
  '--create-note-description-text',
  '--create-note-cancel-button',
  '--create-note-save-button',
  '--create-note-title-text',
  '--search-toggle-button',
  '--book-header-menu-font-size-option',
  '--ion-modal-header-color',
  '--ion-modal-header-font-color',
  '--button-background-color',
  '--button-font-color',
  '--help-accent-color'
];

// ICON TABLE
export const EXCLUDED_ICON_KEYS: string[] = ['id', 'login_logo', 'dashboard_img', 'login_bg'];
export const IMAGE_KEYS = [
  'id',
  'logo',
  'login_logo',
  'login_bg',
  'dashboard_img',
  'dashboard-footer-icon',
  'dashboard-intro-image',
  'dashboard-header-logo',
  'dashboard-header-logo2',
  'dashboard-header-logo3',
  'dashboard-header-background-image',
  'dashboard-top-right-logo',
  'forgot-password-page-background-image',
  'registration-page-background-image',
  'login-page-background-image',
  'login-logo',
  'login-logo2',
  'login-logo3',
];

export const TEXT_KEYS = [
  'login-text-first-line-de',
  'login-text-first-line-en',
  'login-text-second-line-de',
  'login-text-second-line-en',
  'registration-text-de',
  'registration-text-en',
  'forgot-password-text-de',
  'forgot-password-text-en',
  'dashboard-top-text-right-de',
  'dashboard-top-text-right-en',
  'dashboard-headline-text-de',
  'dashboard-headline-text-en',
  'dashboard-intro-text-de',
  'dashboard-intro-text-en',
  'dashboard-eBook-headline-text-de',
  'dashboard-eBook-headline-text-en',
  'dashboard-footer-text-de',
  'dashboard-footer-text-en',
];

// fonts' table keys
export const EXCLUDED_FONT_KEYS: string[] = [];
export const FONT_KEYS = [
  'login-text-font',
  'login-form-button-font',
  'registration-text-font',
  'registration-button-font',
  'forgot-password-text-font',
  'forgot-password-button-font',
  'dashboard-top-text-right-font',
  'dashboard-headline-font',
  'dashboard-intro-font',
  'dashboard-eBook-headline-font',
  'dashboard-teaser-font',
  'dashboar-footer-text-font',
];
