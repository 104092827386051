<ion-header class="ion-modal-header">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="header-title ion-text-left" l10nTranslate> HelpAndSupport.Header </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid class="Content">
    <ion-row class="Title">
      <ion-label l10nTranslate> HelpAndSupport.Title</ion-label>
    </ion-row>
    <ion-row class="IntroText">
      <ion-text l10nTranslate> HelpAndSupport.IntroText
      </ion-text>
    </ion-row>
    <ion-row class="Actions">
      <ion-col sizeXs="6" sizeSm="6" sizeMd="6" sizeLg="6">
        <div (click)="openTipps('search')" class="ActionButton">
          <ion-icon class="ActionIcon"
          src="../../../assets/icon/circle_search.svg"
        ></ion-icon>
          <ion-label class="ActionLabel" l10nTranslate> HelpAndSupport.Search</ion-label>
        </div>
      </ion-col>
      <ion-col sizeXs="6" sizeSm="6" sizeMd="6" sizeLg="6">
        <div (click)="openTipps('downloads')" class="ActionButton">
          <ion-icon class="ActionIcon"
          src="../../../assets/icon/circle_download.svg"
        ></ion-icon>
          <ion-label class="ActionLabel" l10nTranslate> HelpAndSupport.Downloads</ion-label>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>
<ion-footer class="ion-no-border footer">
  <ion-button href="mailto:support@litello.com" class="ContactSupportButton"><ion-label l10nTranslate> HelpAndSupport.ContactSupport</ion-label></ion-button>
</ion-footer>
