import { ToastService } from './../../services/toast/toast.service';
import { Capacitor } from '@capacitor/core';
import { BooksService } from '../../services/books/books.service';
import { EpubRenderService } from '../services/rendition/epubrender.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewRef,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { Animations } from './book.component.animations';
import { ImagePopupComponent } from '../components/image-popup/image-popup.component';
import { BehaviorSubject, firstValueFrom, fromEvent, interval, Observable, Subject, Subscription } from 'rxjs';
import { NavItem } from 'epubjs/types/navigation';
import { SlidePanelState } from '../components/slide-panel/slide-panel.component';
import { BookHeaderComponent } from '../components/book-header/book-header.component';
import { Attachment, BooksService as BooksApiService, Collections } from '../../services/rest-client/rest-client.service';
import { ThemeService } from '../../services/themes/theme.service';
import { DeviceDetectorService } from '../../util/device-detector/device-detector.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Constants } from '../../PODO/constants';
import { StatusBar } from '@capacitor/status-bar';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Network } from '@capacitor/network';
import { ClientBook } from '../../PODO/clientBook';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { SearchService } from '../services/search/search.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService, toNumber } from 'angular-l10n';
import { domains } from '../../../environments/domains';

const COLLECTION_STORAGE_KEY = 'book_collection';

export const EPUB_AREA_ID = 'currentArea';

interface History {
  pageNumber: number;
}

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss'],
  animations: Animations,
})
export class BookComponent implements OnInit, OnDestroy, AfterViewInit {

  public isOnline: boolean = false;

  @HostListener('window:resize', ['$event']) onWindowResize($event: UIEvent) {
    this.handleWindowResize();
  }

  @HostListener('document:keydown', ['$event']) async onKeyDown($event: KeyboardEvent) {
    if (
      this.slidePanelState === SlidePanelState.CLOSED &&
      this.tableOfContentsOpened === false &&
      !(await this.modalCtrl.getTop())
    ) {
      switch ($event.key) {
        case 'ArrowLeft':
          this.prev();
          break;
        case 'ArrowRight':
          this.next();
          break;
        case 'ArrowUp':
          this.scrollUp();
          break;
        case 'ArrowDown':
          this.scrollDown();
          break;
      }
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler() {
    this.analyticsService.updateBookProgress();
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler() {
    this.analyticsService.updateBookProgress();
  }

  @ViewChild('epubArea', { read: ElementRef })

  readonly expLength = 2;
  readonly lastLength = 1;
  secondLastLength = 2;

  public readonly EPUB_AREA_ID = 'currentArea';

  private epubArea: ElementRef | undefined;
  private bookHeaderRef: ComponentRef<BookHeaderComponent> | undefined;

  public book: any | undefined = undefined;
  public maxPages: number = 1;

  private _currentPage: number = 0;

  public get currentPage(): number {
    return this._currentPage;
  }

  public set currentPage(value: number) {
    if (
      value !== this._currentPage &&
      value < this.maxPages &&
      value > -1
    ) {
      this._currentPage = value;
      this.changePageEmitter.next(this.currentPage);
      this.ref.detectChanges();
    }
  }

  private changePageEmitter: Subject<number> = new Subject();
  private forceManualPageChange: boolean = false;

  public bookisrendering: boolean = false;

  public chapters: NavItem[] | undefined = undefined;
  public tableOfContentsOpened: boolean = false;
  public selectedChapter: string = '';
  public redirectedChapter: string = '';

  public slidePanelState: SlidePanelState = SlidePanelState.CLOSED;
  public activatedViewId: string = '';

  private newTextSelections: Map<string, number> = new Map();
  private lastSelection: any = {};

  private histories: History[] = [];
  private subscriptions: Subscription[] = [];

  private tableScrollSetupStatus: BehaviorSubject<string> = new BehaviorSubject<string>('onprogress');
  private scrollPosMap: Map<number, any> = new Map<number, number>();

  public menuMobileView: boolean = false;
  public menuMobileViewChanged: Subject<boolean> = new Subject<boolean>();

  public attachmentLink: string = '';
  public linkNameChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public scrollingEnabled: boolean = true;
  public scrolling: Subject<void> = new Subject<void>();
  public pageHistory: Subject<void> = new Subject<void>();
  public emitClosedActiveMenu: Subject<void> = new Subject<void>();

  emitMobileViewValueToChild($event: any) {
    this.menuMobileViewChanged.next($event);
  }

  emitEventToChild($event: any) {
    this.scrolling.next($event);
  }

  emitHistoryEventToChild($event: any) {
    this.pageHistory.next($event);
  }

  emitMenuChanges($event: any) {
    this.emitClosedActiveMenu.next($event);
  }

  public isEdit: boolean = false;
  public navigateAway: Subject<string | undefined> = new Subject<string | undefined>();
  public isMenuOpen: boolean = false;
  public shouldRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public toc: any;

  get zoom(): number {
    return this.rendering.textZoomLevel.value;
  }

  public bookCoverUrl: any = '';
  public bookTitle: any = '';
  public attachmentsExists: boolean = false;

  // Variables for touch events
  public touchStartPosX: number = 0;
  public touchEndPosX: number = 0;
  public touchStartPosY: number = 0;
  public touchEndPosY: number = 0;

  public isEditing = () =>
    this.activatedViewId === 'note' ||
    this.activatedViewId === 'thread-message' ||
    this.activatedViewId === 'thread-new';

  public bookCollection: ClientBook[] | undefined;

  constructor(
    private rendering: EpubRenderService,
    private route: ActivatedRoute,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private ref: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
    private translationService: L10nTranslationService,
    public platform: Platform,
    private renderer: Renderer2,
    public themeService: ThemeService,
    private booksApiService: BooksApiService,
    public deviceDetector: DeviceDetectorService,
    private spinningService: SpinnerService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private booksService: BooksService,
    private toastService: ToastService,
    private searchService: SearchService,
    private analyticsService: AnalyticsService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    fromEvent(window, 'popstate').subscribe((e) => {
      this.spinningService.hideSpinner();
    });

    Network.getStatus().then((status) => {
      this.isOnline = status.connected;
    });
    Network.addListener('networkStatusChange', async (status) => {
      this.isOnline = status.connected;
    });
  }

  public ngOnInit() {
    this.subscriptions.push(
      this.changePageEmitter.pipe(debounceTime(300), distinctUntilChanged()).subscribe(this.onPageChange),
      this.rendering.textZoomLevel.subscribe(() => {
        this.updateHeaderChapter();
        this.ref.detectChanges();
      }),
      this.tableScrollSetupStatus.subscribe((status: string) => this.onTableStatusChange(status))
    );

    interval(30000).subscribe(() => {
      this.analyticsService.updateBookProgress();
    });

    interval(100).subscribe(() => {
      this.updateIframeSize();
    });
  }

  public ngOnDestroy() {
    this.rendering.stopRendering();
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.searchService.reset();
    this.analyticsService.updateBookProgress();
  }

  public async ngAfterViewInit() {
    let bookId = Number(this.route.snapshot.paramMap.get('id'));

    // Check if domain has a default book and set the book id
    const domain = window.location.hostname;
    if (domains[domain]) {
      console.log("Domain " + domain + " has a default book id: " + domains[domain]["id"]);
      bookId = domains[domain]["id"];
    }

    const book = await this.booksService.loadBook(bookId);

    if (!book) {
      console.error('Book not found')
      this.toastService.showErrorMessage(this.translationService.translate('Book.NotFound'));
      return;
    }

    this.analyticsService.openBook(book.id);
    this.spinningService.showSpinner();
    this.book = book;
    this.epubArea && (this.epubArea.nativeElement.style.opacity = 0);
    this.bookCoverUrl = this.book.urlCover;
    this.bookTitle = this.book.title;
    this.bookCollection = await this.getBookCollection(book.id);

    /**
     * This will fetch the attachments and store a boolean value
     * @todo It would probably be better to initialize the attachment service and see if the book has attachments
     * The current implementation calls the API twice 
     */
    this.checkIfAttachmentsExists(book.id);

    if (!book.opf_file) {
      console.error('Book opf file not found');
    }
    await this.renderBook(book.opf_file);

    // Check if url has cfi parameter and navigate to the page
    let cfi = this.route.snapshot.queryParams['cfi']
    if (cfi) {
      try {
        if (!cfi.startsWith("epubcfi(")) {
          cfi = "epubcfi(" + cfi + ")";
        }
        await this.rendering.goToCfi(cfi)
      }
      catch (e: any) {
        console.error('Error navigating to cfi')
      }
    }

    // Check if url has page parameter and navigate to the page
    let page = this.route.snapshot.queryParams['page']
    if (page) {
      try {
        await this.goToPage(toNumber(page) - 1);
      }
      catch (e: any) {
        console.error('Error navigating to page')
      }
    }
  }

  async ionViewWillEnter() {
    if (Capacitor.getPlatform() === 'ios') {
      await StatusBar.hide();
    }
    this.menuCtrl.enable(true, 'main-menu').then((enabled) => {
      if (!enabled) {
        console.warn('Menu control is not enabled.');
      }
    });
    this.handleWindowResize();
  }

  async ionViewWillLeave() {
    this.closePanel();
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.detachBookHeader();
    if (Capacitor.getPlatform() === 'ios') {
      await StatusBar.show();
    }
  }

  private detachBookHeader() {
    // @ts-ignore
    const headerToolbars: NodeList = document.querySelectorAll('.on-header');
    headerToolbars.forEach((toolbar) => {
      // @ts-ignore
      toolbar.style.display = 'block';
    });
    const toolBarElm: HTMLIonToolbarElement | null = document.querySelector('ion-toolbar');
    if (toolBarElm) {
      if (!this.deviceDetector.isDesktop()) {
        const appMenuButton = toolBarElm.querySelector('ion-button');
        if (appMenuButton) {
          appMenuButton.style.display = 'block';
        }
      }

      const appLangSelect = toolBarElm.querySelector('select');
      if (appLangSelect) {
        appLangSelect.style.display = 'block';
      }
      const appIconImg = toolBarElm.querySelector('img');
      if (appIconImg) appIconImg.style.visibility = 'visible';

      const appBookHeader = toolBarElm.querySelector('app-book-header');
      if (appBookHeader) {
        toolBarElm.removeChild(appBookHeader);
      }
    }
  }

  /**
   * TODO research for better way
   * why is it injected and not using the component selector into the html?
   */
  private insertBookHeader() {
    if (this.bookHeaderRef) return;
    const headerToolbars: NodeList = document.querySelectorAll('.on-header');
    headerToolbars.forEach((toolbar: any) => {
      toolbar.style.display = 'none';
    });

    const toolBarElm: HTMLIonToolbarElement | null = document.querySelectorAll('ion-toolbar').item(0);

    if (toolBarElm) {
      if (!this.deviceDetector.isDesktop()) {
        const appMenuButton = toolBarElm.querySelector('ion-button');
        if (appMenuButton) {
          appMenuButton.style.display = 'none';
        }
      }

      const appLangSelect = toolBarElm.querySelector('select');
      if (appLangSelect) {
        appLangSelect.style.display = 'none';
      }
      const appIconImg = toolBarElm.querySelector('img');
      if (appIconImg) appIconImg.style.visibility = 'collapse';

      const bookHeaderInstance = this.createBookHeaderInstance();

      bookHeaderInstance.onNavigate.subscribe(() => {
        this.navigateToPrevPageAndSwap();
      });
      bookHeaderInstance.onToggleTableOfContents.subscribe(() => this.toggleTableOfContentsView());
      bookHeaderInstance.openBookLibrary.subscribe(() => this.openBookLibrary());
      bookHeaderInstance.zoomIn.subscribe(() => this.zoomIn());
      bookHeaderInstance.zoomOut.subscribe(() => this.zoomOut());


      if (!this.deviceDetector.isDesktop()) {
        bookHeaderInstance.onToggleSearch.subscribe(() => this.toggleSearchView());
      }
      bookHeaderInstance.notifyChanges();
      const domElem = (this.bookHeaderRef!!.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      if (domElem) {
        toolBarElm.appendChild(domElem);
      }
    }
  }

  public doSomethingOnScroll($event: any) {
    const fab = <HTMLElement>document.querySelector('ion-fab')!;

    if ($event.detail.deltaY < -100) {
      if (fab) {
        //fab.style.display = "block";
        //document.querySelector('ion-fab')!.className += " active";
        document.getElementById('book-fab-btn')!.className = 'ion-fabactive';
      }
    } else if ($event.detail.deltaY > 100) {
      if (fab) {
        //fab.style.display = "none";
        document.getElementById('book-fab-btn')!.className = 'ion-fabactive2';
      }
    }
  }

  /**
   * Redirects to another book
   * @param id Id of the book to be loaded
   * @param anchor Html anchor to be scrolled to
   */
  redirect(id: number, anchor: string) {
    this.booksService.loadBook(id).then((book) => {
      if (book) {
        this.renderRedirectedBook(book, anchor);
      }
      else {
        this.toastService.showErrorMessage('Book not found');
        console.log('Book not found');
      }
    });
  }

  async renderRedirectedBook(book: ClientBook, anchor: string) {
    this.closePanel();
    this.rendering.stopRendering();
    this.bookisrendering = true;
    this.spinningService.showSpinner();

    this.searchService.reset();
    this.book = book;
    this.ref.detectChanges();
    this.epubArea && (this.epubArea.nativeElement.style.opacity = 0);
    this.bookCoverUrl = this.book?.urlCover;
    this.bookTitle = this.book?.title;

    if (!this.deviceDetector.isDesktop() && !this.menuMobileView) {
      this.removeLitelloAppHeader();
    }

    if (anchor) {
      this.redirectedChapter = anchor;
    }

    let bookFile;
    bookFile = book.opf_file;

    if (bookFile) {
      await this.renderBook(bookFile).then(
        () => {
          if (this.redirectedChapter !== '') {
            this.goToPageToc(this.redirectedChapter);
            this.redirectedChapter = '';
          } else {
            this.bookisrendering = false;
          }
        }
      );
    } else {
      console.error(`Error, bookFile: ${bookFile}`);
    }
  }

  public goToPageToc(href: string) {
    this.rendering.getCfiFromHref(href).then((cfi) => {
      if (cfi) {
        this.rendering.goToCfi(cfi).then(() => {
          const hash = href.substring(href.indexOf('#'));
          setTimeout(() => {
            this.rendering.goToSubSection(hash);
          }, 500);
          this.bookisrendering = false;
        });
      }
    });
  }

  private createBookHeaderInstance() {
    if (!this.bookHeaderRef) {
      this.bookHeaderRef = this.viewContainerRef.createComponent(BookHeaderComponent);
    }
    return <BookHeaderComponent>this.bookHeaderRef.instance;
  }

  public openMenu() {
    this.isMenuOpen = true;
  }

  public closeMenu() {
    this.isMenuOpen = false;
    this.menuCtrl.close().then((closed) => {
      if (!closed) console.warn('Menu control not closed.');
    });
  }

  private onTableStatusChange(status: string) {
    if (status === 'done') {
      this.bringSvgMaskGroupToTop();
    }
  }

  public notifyChanges() {
    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  public openBookLibrary() {
    this.router.navigate([Constants.URL.Books]);
    this.showLitelloAppHeader();
  }

  checkIfAttachmentsExists(bookId: any) {
    return this.booksApiService
      .getAttachments(bookId)
      .subscribe((attachmentsList: Attachment[]) => {
        this.attachmentsExists = attachmentsList && attachmentsList.length > 0;
      });
  }

  fetchBookCollection(bookId: number): Observable<Collections> {
    return this.booksApiService.getCollections(bookId);
  }

  public async getBookCollection(bookId: number): Promise<ClientBook[] | undefined> {
    const collections = await firstValueFrom(this.fetchBookCollection(bookId));
    const collection = Object.values(collections)?.[0];
    return collection?.filter(x => x.id !== bookId)
  }


  private removeLitelloAppHeader() {
    const appTable = <HTMLElement>document.querySelector('app-slide-panel')!;
    appTable.querySelector('ion-content')!.style.top = '0px';

    const header = <HTMLElement>document.querySelector('litello-app-header')!;
    if (header) {
      header.style.display = 'none';
    }

    const content = <HTMLElement>document.getElementById('book-content')!;
    if (content) {
      content.style.paddingTop = '0px';
    }

    const context = <HTMLElement>document.querySelector('app-context-menu')!;
    if (context) {
      context.style.display = 'none';
    }
  }

  private showLitelloAppHeader() {
    const header = <HTMLElement>document.querySelector('litello-app-header')!;
    if (header) {
      header.style.display = 'block';
    }
    const content = <HTMLElement>document.getElementById('book-content')!;
    if (content) {
      content.style.paddingTop = '60px';
    }
    const appTable = <HTMLElement>document.querySelector('app-slide-panel')!;
    appTable.querySelector('ion-content')!.style.top = '60px';
  }

  private async renderBook(signedUrl: string) {
    await this.rendering
      .renderTo(
        this.EPUB_AREA_ID,
        signedUrl,
        (pageNumber: number) => {
          if (this.forceManualPageChange === false) {
            this.currentPage = pageNumber;
          }
          this.forceManualPageChange = false;
        }
      )
      .then(
        async () => {
          await this.setTotalPages();
          this.currentPage = 0;
          this.addPlaceHolderToBookPage();

          this.rendering.attachCallback('touchstart', this.onTouchstartListener.bind(this));
          this.rendering.attachCallback('touchend', this.onTouchendListener.bind(this));
          this.rendering.attachCallback('relocated', this.onRelocate.bind(this));
          this.rendering.attachCallback('click', this.onPageClick.bind(this));
          this.rendering.attachCallback('keydown', this.onKeyDown.bind(this));

          this.restoreScrollPosition();

          this.initTextSelectionsForNewScroll();
          this.insertBookHeader();

          this.ref.detectChanges();
          await this.spinningService.hideSpinner();
          this.rendering.getFlattenedChapters();
          this.loadChapters();
          return true
        },
        async (error) => {
          console.error("Couldn't open the book, \n " + JSON.stringify(error));
          await this.spinningService.hideSpinner();
        }
      );
  }

  private addPlaceHolderToBookPage() {
    const placeholder = document.createElement('div');
    placeholder.setAttribute('style', 'height:0');
    placeholder.setAttribute('class', 'placeholder');
    if (this.epubArea) {
      this.epubArea.nativeElement.appendChild(placeholder);
      this.epubArea.nativeElement.style.opacity = 1;
    }
  }

  private async setTotalPages() {
    await this.rendering.totalPages().then((totalPages) => {
      this.maxPages = totalPages;
    });
  }

  private onRelocate = () => {
    this.setCurrentChapter();
    this.setUpScrollListenerAndMaskToTableElements();
  }

  /**
   * This function saves the current touch position to use for page swipe gesture
   * @param e Touchend event
   * @returns void
   */
  private onTouchstartListener = (e: any) => {
    this.touchStartPosX = e.changedTouches[0].clientX;
    this.touchStartPosY = e.changedTouches[0].screenY;
  }

  /**
   * This function calls the page swipe gesture handler
   * @param e Touchend event
   * @returns void
   */
  private onTouchendListener = (e: any) => {
    this.touchEndPosX = e.changedTouches[0].clientX;
    this.touchEndPosY = e.changedTouches[0].screenY;
    this.handleGesture();
  }


  /**
   * Handle the page swipe gesture and move to next or previous page
   * @returns void
   */
  private handleGesture() {
    let swipeDiff = this.touchStartPosX - this.touchEndPosX;
    let scrollDiff = this.touchStartPosY - this.touchEndPosY;

    //This condition checks to make sure if there  is no scrolling involved
    if (scrollDiff > 50 || scrollDiff < -50) return;
    // Check if the swipDiff is bigger than 50px in either direction
    if (Math.abs(swipeDiff) < 50) return;

    if (this.touchEndPosX < this.touchStartPosX)
      this.next();
    else
      this.prev();
  }

  /**
   * Handle a click on a page and perform the following:
   * Following actions are performed based on the target element:
   * - Image            - Open the image in a modal
   * - Attachment link  - Toggle the attachment view and show the attachment
   * - Book link        - Redirect to the book
   * - Subsection link  - Switch to the subsection
   * If none of the above conditions are met, then the click is ignored
   * @param e MouseEvent
   * @returns void
   */
  private onPageClick = (e: MouseEvent) => {
    if (!e.target)
      return;

    const targetElement = e.target as HTMLElement;

    if (targetElement.tagName === 'IMG') {
      this.handleImageClick(e);
      return;
    }

    const aTagIsParent: boolean = this.checkIfAtagIsParent(e);
    if (targetElement.tagName != 'A' && !aTagIsParent) {
      return;
    }

    let anchor: HTMLAnchorElement = <HTMLAnchorElement>targetElement;
    if (aTagIsParent) {
      anchor = <HTMLAnchorElement>targetElement.parentNode;
    }

    if (anchor?.href?.includes('attachments')) {
      this.attachmentLink = anchor.href.split(':')[1];
      if (this.activatedViewId !== 'attachment') {
        this.togglePanel('attachment');
      }
      this.notifyChanges();
    } else if (anchor?.href?.startsWith('book')) {
      const bookLink: string[] = anchor.href.split('::');
      const linkId = parseInt(bookLink[1]);
      const linkAnchor = bookLink[2];
      this.redirect(linkId, linkAnchor);
    } else if (anchor?.href && anchor.href.includes('#')) {
      console.log("onPageClick: handleSubSectionClick");
      this.handleSubSectionClick(anchor);
    } else {
      // Open external link in another tab
      // This is done because the iframe is not allowed to open external links
      window.open(anchor.href, '_blank');
    }
  }

  /**
   * Check if an html element is a child of an a tag
   * @param e target element
   * @returns true if the target element is a child of an a tag
   */
  checkIfAtagIsParent(e: any): boolean {
    const target = (e.target as HTMLElement);
    return (
      (target &&
        target.parentNode?.nodeName === 'A' &&
        (target.tagName === 'SPAN' ||
          target.tagName === 'svg' ||
          target.tagName === 'STRONG'))
    );
  }

  private handleSubSectionClick(anchor: HTMLAnchorElement) {
    const hash = anchor.href.substring(anchor.href.indexOf('#'));
    setTimeout(() => {
      this.rendering.goToSubSection(hash);
    }, 500);
  }

  private handleImageClick(e: MouseEvent) {
    const srcElement: HTMLImageElement = <HTMLImageElement>e.target;
    if (
      !srcElement!.currentSrc.toLocaleLowerCase().includes('cover')
      && !srcElement.classList.contains('no-app-modal')
    ) {
      this.modalCtrl
        .create({
          component: ImagePopupComponent,
          componentProps: { src: srcElement.currentSrc },
          cssClass: 'ion-main-modal-mobile',
        })
        .then((popover) => {
          popover.present().then(() => console.debug('Image modal is present.'));
        });
    }
  }

  private setUpScrollListenerAndMaskToTableElements() {
    const tableElements = this.getTableElements();
    if (tableElements && tableElements.length > 0) {
      let count = 0;
      tableElements.forEach((tableElm) => {
        const tableContainer = tableElm.parentElement;
        if (tableContainer && (tableContainer.style.overflowX == 'auto' || tableContainer.scrollWidth > innerWidth)) {
          this.setScrollListenersToTableContainer(tableContainer).then(() => {
            this.addSvgGroupLayer()
              .then((maskLayer) => {
                this.attachMaskToHideOverflowScroll(maskLayer, tableContainer).then(() => {
                  count++;
                  this.tableScrollSetupStatus.next(count == tableElements.length ? 'done' : 'onprogress');
                });
              })
              .catch((e) => {
                console.debug('addSvgGroupLayer(): Error, ' + e);
                this.tableScrollSetupStatus.next('retry');
              });
          });
        }
      });
    } else {
      this.tableScrollSetupStatus.next('done');
    }
  }

  /**
   * This seems to be a workaround because of epub.js but worth investigating.
   * @todo: Find a better solution to hide the overflow scroll of the table container.
   */
  private async attachMaskToHideOverflowScroll(maskSvgLayer: SVGSVGElement, tableContainer: HTMLElement) {
    const tableContainerParentRect = tableContainer.parentElement!.getBoundingClientRect();
    const tableClientRect = tableContainer.getBoundingClientRect();

    if (tableContainerParentRect.left == 0) {
      const maskOuterLeft = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      maskOuterLeft.setAttributeNS(null, 'class', 'mask');
      maskOuterLeft.setAttributeNS(null, 'x', '0');
      maskOuterLeft.setAttributeNS(null, 'y', `${tableClientRect.top}`);
      maskOuterLeft.setAttributeNS(null, 'width', `${tableClientRect.left}`);
      maskOuterLeft.setAttributeNS(null, 'height', `${tableClientRect.height}`);
      maskOuterLeft.setAttributeNS(null, 'fill', 'var(--ion-background-color)');

      const maskOuterRight = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      maskOuterRight.setAttributeNS(null, 'class', 'mask');
      maskOuterRight.setAttributeNS(null, 'x', `${tableClientRect.right}`);
      maskOuterRight.setAttributeNS(null, 'y', `${tableClientRect.top}`);
      maskOuterRight.setAttributeNS(null, 'width', `${tableClientRect.left}`);
      maskOuterRight.setAttributeNS(null, 'height', `${tableClientRect.height}`);
      maskOuterRight.setAttributeNS(null, 'fill', 'var(--ion-background-color)');
      maskSvgLayer.appendChild(maskOuterLeft);
      maskSvgLayer.appendChild(maskOuterRight);
      return Promise.resolve();
    }

    const { color, wrapper } = this.pickTableBackgroundColor(tableContainer);
    const innerMaskWidth = (innerWidth - tableClientRect.width) / 2;
    const maskInnerLeft = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    maskInnerLeft.setAttributeNS(null, 'class', 'mask');
    maskInnerLeft.setAttributeNS(null, 'x', `${tableClientRect.left - (innerWidth - tableClientRect.width) / 2}`);
    maskInnerLeft.setAttributeNS(null, 'y', `${tableClientRect.top}`);
    maskInnerLeft.setAttributeNS(null, 'width', `${innerMaskWidth}`);
    maskInnerLeft.setAttributeNS(null, 'height', `${tableClientRect.height}`);
    maskInnerLeft.setAttributeNS(null, 'fill', `${color}`);

    const maskInnerRight = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    maskInnerRight.setAttributeNS(null, 'class', 'mask');
    maskInnerRight.setAttributeNS(null, 'x', `${tableClientRect.right}`);
    maskInnerRight.setAttributeNS(null, 'y', `${tableClientRect.top}`);
    maskInnerRight.setAttributeNS(null, 'width', `${innerMaskWidth}`);
    maskInnerRight.setAttributeNS(null, 'height', `${tableClientRect.height}`);
    maskInnerRight.setAttributeNS(null, 'fill', `${color}`);

    const outerMaskWidth = wrapper!.toString() == 'body' ? 0 : (wrapper as HTMLDivElement).getBoundingClientRect().left;
    const outerRightMaskX =
      wrapper!.toString() == 'body' ? tableClientRect.right : (wrapper as HTMLDivElement).getBoundingClientRect().right;

    const maskOuterLeft = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    maskOuterLeft.setAttributeNS(null, 'class', 'mask');
    maskOuterLeft.setAttributeNS(null, 'x', '0');
    maskOuterLeft.setAttributeNS(null, 'y', `${tableClientRect.top}`);
    maskOuterLeft.setAttributeNS(null, 'width', `${outerMaskWidth == 0 ? tableClientRect.left : outerMaskWidth}`);
    maskOuterLeft.setAttributeNS(null, 'height', `${tableClientRect.height}`);
    maskOuterLeft.setAttributeNS(null, 'fill', 'var(--ion-background-color)');

    const maskOuterRight = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    maskOuterRight.setAttributeNS(null, 'class', 'mask');
    maskOuterRight.setAttributeNS(null, 'x', `${outerRightMaskX}`);
    maskOuterRight.setAttributeNS(null, 'y', `${tableClientRect.top}`);
    maskOuterRight.setAttributeNS(null, 'width', `${outerMaskWidth == 0 ? tableClientRect.left : outerMaskWidth}`);
    maskOuterRight.setAttributeNS(null, 'height', `${tableClientRect.height}`);
    maskOuterRight.setAttributeNS(null, 'fill', 'var(--ion-background-color)');

    maskSvgLayer.appendChild(maskInnerLeft);
    maskSvgLayer.appendChild(maskInnerRight);
    maskSvgLayer.appendChild(maskOuterLeft);
    maskSvgLayer.appendChild(maskOuterRight);

    return Promise.resolve();
  }

  private pickTableBackgroundColor(tableContainer: HTMLElement) {
    if (tableContainer.parentElement) {
      const computedColor = getComputedStyle(tableContainer.parentElement).backgroundColor;
      if (computedColor != 'rgba(0, 0, 0, 0)') {
        return { color: computedColor, wrapper: tableContainer.parentElement };
      } else {
        if (tableContainer.parentElement.parentElement) {
          const parentComputedColor = getComputedStyle(tableContainer.parentElement.parentElement).backgroundColor;
          if (parentComputedColor != 'rgba(0, 0, 0, 0)') {
            return { color: parentComputedColor, wrapper: tableContainer.parentElement.parentElement };
          } else {
            if (tableContainer.parentElement.parentElement.parentElement) {
              const parentParentComputedColor = getComputedStyle(
                tableContainer.parentElement.parentElement.parentElement
              ).backgroundColor;
              if (parentParentComputedColor != 'rgba(0, 0, 0, 0)')
                return {
                  color: parentParentComputedColor,
                  wrapper: tableContainer.parentElement.parentElement.parentElement,
                };
            }
          }
        }
      }
    }
    return { color: 'var(--ion-background-color)', wrapper: 'body' };
  }

  private async addSvgGroupLayer(): Promise<SVGSVGElement> {
    const svgMaskGroupLayer = document.getElementsByClassName('mask-group').item(0);

    if (svgMaskGroupLayer) {
      return Promise.resolve(svgMaskGroupLayer as SVGSVGElement);
    }

    const newLayer = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    newLayer.setAttributeNS(null, 'class', 'mask-group');
    newLayer.setAttributeNS(null, 'pointer-events', 'none');
    newLayer.setAttributeNS(null, 'height', `100%`);
    newLayer.setAttributeNS(null, 'width', '100%');
    newLayer.style.position = 'absolute';
    newLayer.style.left = '0';
    newLayer.style.top = '0';

    const epubViews = document.getElementsByClassName('epub-view');
    if (epubViews.length > 0) {
      epubViews[0].append(newLayer);
    } else {
      return Promise.reject('epubView not exists.');
    }

    return Promise.resolve(newLayer);
  }

  private bringSvgMaskGroupToTop() {
    const svgMaskGroupLayer = document.getElementsByClassName('mask-group').item(0);
    if (svgMaskGroupLayer) {
      (svgMaskGroupLayer as HTMLElement).style.zIndex = '10';
    }
  }

  private async setScrollListenersToTableContainer(tableContainer: HTMLElement) {
    if (tableContainer.onscroll != null) {
      return;
    }
    tableContainer.style.overflowX = 'auto';
  }

  private getTableElements(): NodeListOf<HTMLTableElement> | undefined {
    const iframe = document.querySelector('iframe');
    if (iframe) {
      const contentDocument = iframe.contentDocument;
      if (contentDocument) {
        return contentDocument.body.querySelectorAll('table');
      }
    }
  }

  private initTextSelectionsForNewScroll() {
    this.newTextSelections.clear();
    this.tableScrollSetupStatus.next('onprogress');
  }

  private saveHistory(pageNumber: number) {
    if (this.histories.length > 0 && this.histories[this.histories.length - this.lastLength].pageNumber === pageNumber) {
      return;
    }

    this.histories.push({ pageNumber });

    this.emitHistoryEventToChild(this.histories);
    this.updateHeaderChapter();
  }

  public navigateToPrevPageAndSwap() {
    if (this.histories.length >= 2) {
      const current = this.histories[this.histories.length - this.lastLength];
      const last = this.histories[this.histories.length - this.secondLastLength];

      if (last) {
        if (last.pageNumber == current.pageNumber) {
          this.histories.pop();
          this.navigateToPrevPageAndSwap();
          return;
        }

        this.goToPage(last.pageNumber);
        this.secondLastLength += 1;
        const checkLast = this.histories[this.histories.length - this.secondLastLength];
        if (!checkLast) {
          this.secondLastLength = 2;
          this.histories = [];
        }
        return;
      }
    }
  }

  public navigateToPrevHistory() {
    if (this.histories.length == 0) {
      this.navCtrl.back();
    } else {
      const last = this.histories[this.histories.length - this.lastLength];
      if (last) {
        if (last.pageNumber == this.currentPage) {
          this.histories.pop();
          this.navigateToPrevHistory();
          return;
        }
        this.goToPage(last.pageNumber);
        return;
      }
      this.navCtrl.back();
    }
  }

  private async goToPage(page: number) {
    await this.rendering.displayCfi(page.toString())
  }

  private setCurrentChapter() {
    const info = this.rendering.getChapterInformationForDiscussion();
    if (info) {
      this.toggleChapter(info.selectedChapter);
    }
  }

  private loadChapters() {
    this.rendering.getTOC().then((toc) => {
      this.toc = toc;
    });
    this.rendering.getChapters().then((chapters) => {
      this.chapters = chapters;
    });
  }

  public toggleChapter(chapter: string) {
    this.selectedChapter = chapter;
    this.updateHeaderChapter();
  }

  public toggleTableOfContentsView() {
    this.closePanel();
    this.panelClosed();
    this.tableOfContentsOpened = !this.tableOfContentsOpened;
    if (this.bookHeaderRef) {
      const bookHeaderInstance = <BookHeaderComponent>this.bookHeaderRef.instance;
      bookHeaderInstance.tableOfContentsOpened = this.tableOfContentsOpened;
      bookHeaderInstance.notifyChanges();
    }
    this.ref.detectChanges();
  }

  /**
   * This is mobile only.
   * */
  public toggleSearchView() {
    if ((this.tableOfContentsOpened = true)) {
      this.tableOfContentsOpened = !this.tableOfContentsOpened;
    }
    this.togglePanel('search');
    this.ref.detectChanges();
  }

  /**
   * This is mobile only.
   * */
  public toggleAttachmentView() {
    if ((this.tableOfContentsOpened = true)) {
      this.tableOfContentsOpened = !this.tableOfContentsOpened;
    }
    this.togglePanel('attachment');
    this.ref.detectChanges();
  }

  public toggleCollectionOverview() {
    if (this.tableOfContentsOpened) {
      this.tableOfContentsOpened = false;
    }
    this.togglePanel('collection');
    this.ref.detectChanges();
  }

  public openAttachmentOverview() {
    if (this.tableOfContentsOpened) {
      this.tableOfContentsOpened = false;
    }
    this.togglePanel('attachment');
    this.ref.detectChanges();
  }

  public openCollectionOverview() {
    if (this.tableOfContentsOpened) {
      this.tableOfContentsOpened = false;
    }
    this.togglePanel('collection');
    this.ref.detectChanges();
  }

  private updateHeaderChapter() {
    if (this.bookHeaderRef) {
      const bookHeaderInstance = <BookHeaderComponent>this.bookHeaderRef.instance;
      bookHeaderInstance.book = this.book;
      bookHeaderInstance.bookCoverSrc = this.bookCoverUrl;
      bookHeaderInstance.bookTitle = this.bookTitle;
      bookHeaderInstance.selectedChapter = this.selectedChapter;
      bookHeaderInstance.tableOfContentsOpened = this.tableOfContentsOpened;
      bookHeaderInstance.zoom = this.zoom;
      bookHeaderInstance.previousPageButton = this.histories.length >= 2;
      bookHeaderInstance.notifyChanges();
    }
  }

  private handleWindowResize = () => {
    if (window.innerWidth < 1180 && !this.menuMobileView) {
      this.menuMobileView = true;
      this.emitMobileViewValueToChild(this.menuMobileView);
      this.removeLitelloAppHeader();
    } else if (window.innerWidth >= 1180 && this.menuMobileView) {
      this.menuMobileView = false;
      this.emitMobileViewValueToChild(this.menuMobileView);
      this.showLitelloAppHeader();
    }
  }

  private saveScrollPosition() {
    this.rendering.getScrollTop().then((top) => {
      if (top > 0) {
        this.scrollPosMap.set(this.currentPage, top);
      }
    });
  }

  private restoreScrollPosition() {
    let scrollPos = this.scrollPosMap.get(this.currentPage);
    if (scrollPos !== undefined) {
      this.rendering.scrollTo(scrollPos).then(() => console.debug(`Scroll position restored ${scrollPos}`));
    }
  }

  public toggleBookView(params: any) {
    switch (params.viewId) {
      case 'search':
        this.isEdit = true;
        break;
      case 'attachment':
        this.isEdit = true;
        break;
      case 'book':
        this.isEdit = false;
        if (!this.deviceDetector.isDesktop()) {
          this.closePanel();
          this.createBookHeaderInstance().activatedView = 'book';
        }
        break;
      default:
        this.isEdit = false;
        break;
    }

    this.activatedViewId = params.viewId;
  }

  /**
   * Opens the slide panel and sets the activated view.
   * @param viewId The view to open
   * @returns void
   */
  public togglePanel(viewId: string) {
    if (this.slidePanelState === SlidePanelState.CLOSED) {
      this.openPanel(viewId);
      return;
    }
    if (this.activatedViewId === viewId) {
      this.closePanel();
      return;
    }
    if (viewId)
      this.openPanel(viewId);
    else
      this.closePanel();
  }

  private openPanel(viewId: string) {
    this.slidePanelState = SlidePanelState.OPENED;
    this.activatedViewId = viewId;
  }

  public closePanel() {
    this.slidePanelState = SlidePanelState.CLOSED;
    this.activatedViewId = '';
    this.isEdit = false;
    this.attachmentLink = '';
  }

  public panelOpened(panelWidth: number) {
    const epubViewElm = document.getElementById('book-content');
    const topBarItems = document.getElementById('topBarItems');
    const toolbarElm = document.getElementById('footerbarIdForSideBar');
    if (epubViewElm) {
      const deltaX = panelWidth / 2 + 40;

      this.renderer.setStyle(epubViewElm, '-webkit-transform', `translate3d(-${deltaX}px, 0, 0)`);
      this.renderer.setStyle(epubViewElm, 'transform', `translate3d(-${deltaX}px, 0, 0)`);
      this.renderer.setStyle(epubViewElm, 'transition', `300ms ease-in-out`);
      this.handleWindowResize();

      if (toolbarElm) {
        this.renderer.setStyle(toolbarElm, '-webkit-transform', `translate3d(-${deltaX}px, 0, 0)`);
        this.renderer.setStyle(toolbarElm, 'transform', `translate3d(-${deltaX}px, 0, 0)`);
        this.renderer.setStyle(toolbarElm, 'transition', `300ms ease-in-out`);
      }
      if (topBarItems) {
        this.renderer.setStyle(topBarItems, '-webkit-transform', `translate3d(-${deltaX}px, 0, 0)`);
        this.renderer.setStyle(topBarItems, 'transform', `translate3d(-${deltaX / 2}px, 0, 0)`);
        this.renderer.setStyle(topBarItems, 'transition', `300ms ease-in-out`);
      }
    }
    this.handleSelection();
  }

  public panelClosed() {
    const epubViewElm = document.getElementById('book-content');
    const topBarItems = document.getElementById('topBarItems');
    const toolbarElm = document.getElementById('footerbarIdForSideBar');
    if (epubViewElm) {
      this.renderer.setStyle(epubViewElm, '-webkit-transform', `translate3d(0, 0, 0)`);
      this.renderer.setStyle(epubViewElm, 'transform', `translate3d(0, 0, 0)`);
      this.renderer.setStyle(epubViewElm, 'transition', `300ms ease-in-out`);
      this.handleWindowResize();

      if (toolbarElm) {
        this.renderer.setStyle(toolbarElm, '-webkit-transform', `translate3d(0, 0, 0)`);
        this.renderer.setStyle(toolbarElm, 'transform', `translate3d(0, 0, 0)`);
        this.renderer.setStyle(toolbarElm, 'transition', `300ms ease-in-out`);
      }
      if (topBarItems) {
        this.renderer.setStyle(topBarItems, '-webkit-transform', `translate3d(0, 0, 0)`);
        this.renderer.setStyle(topBarItems, 'transform', `translate3d(0, 0, 0)`);
        this.renderer.setStyle(topBarItems, 'transition', `300ms ease-in-out`);
      }
    }
  }

  public onNavigate() {
    this.tableOfContentsOpened = false;
    this.createBookHeaderInstance().activatedView = 'book';
  }

  /**
   * This fixes a flashing of content, where the text was removed during page swipe but the video placeholders remained.
   */
  public unloadVideos() {
    Array.prototype.forEach.call(document.querySelectorAll('app-video-placeholder'), function (node: any) {
      node.parentNode.removeChild(node);
    });
  }

  public prev = (): void => {
    this.currentPage = this.currentPage - 1;
    this.forceManualPageChange = true;
  }

  public next = (): void => {
    this.currentPage = this.currentPage + 1;
    this.forceManualPageChange = true;
  }

  private scrollUp = (): void => {
    this.rendering.scroll(-50);
  }

  private scrollDown = (): void => {
    this.rendering.scroll(50);
  }

  public onBookContentScrollEnd(): void {
    this.saveScrollPosition();
  }

  public zoomIn(): void {
    if (this.rendering.textZoomLevel.value + 1 <= this.rendering.DEFAULT_MAXZOOM) this.rendering.setZoom(this.rendering.textZoomLevel.value + 1);
  }
  public zoomOut(): void {
    if (this.rendering.textZoomLevel.value - 1 >= this.rendering.DEFAULT_MINZOOM) this.rendering.setZoom(this.rendering.textZoomLevel.value - 1);
  }

  private handleSelection() {
    if (this.lastSelection && this.lastSelection.selectionObject) {
      this.lastSelection.selectionObject.removeAllRanges();
    }
  }

  /**
   * Fires 300ms after the 'currentPage' variable is changed.
   * Renders the page if it wasn't already rendered. This is the case when the page is changed manually using the slider and buttons.
   * @param {number} pageNumber
   */
  private onPageChange = (pageNumber: number): void => {
    if (this.rendering.currentPage !== pageNumber) {
      this.changePage(pageNumber);
    }
    this.saveHistory(pageNumber);
  }

  /**
   * Updates the iframe height to match the height of the content.
   */
  private updateIframeSize() {
    let iframe = document.getElementsByTagName("iFrame")[0];
    if (!(iframe instanceof HTMLIFrameElement))
      return;

    const innerHeight = iframe.contentWindow?.document?.body?.clientHeight + 'px';

    if (innerHeight && innerHeight == iframe.style.height) {
      return;
    }

    let epubView = document.getElementsByClassName("epub-view")[0];
    let epubContainer = document.getElementsByClassName("epub-container")[0];
    if (epubContainer instanceof HTMLElement && epubView instanceof HTMLElement) {
      iframe.style.height = innerHeight;
      epubContainer.style.height = "unset";
      epubView.style.height = "unset";
    }
  }

  /**
   * Changes page to the given page number.
   * @param pageNumber Number of the page to be rendered.
   */
  private async changePage(pageNumber: number): Promise<void> {
    await this.spinningService.showSpinner();

    this.scrollPosMap.delete(pageNumber);
    this.unloadVideos();
    this.ref.detectChanges();
    this.rendering.displayCfi(pageNumber.toString());
    this.emitMenuChanges(true);
  }

}
