import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { L10nTranslationService } from 'angular-l10n';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(public toastController: ToastController, public localization: L10nTranslationService) {}
  readonly minDuration = 4000;
  readonly maxDuration = 6000;

  public showSuccessMessage(message: string) {
    this.presentToast(message, 'success', this.minDuration);
  }

  public showErrorMessage(message: string) {
    this.presentToast(message, 'danger', this.maxDuration);
  }

  public showWarningMessage(message: string) {
    this.presentToast(message, 'warning', -1);
  }

  async presentToast(message: string, color: string, duration: number) {
    const toast = await this.toastController.create({
      position: 'top',
      message: message,
      color: color,
      duration: duration,
      buttons: ["OK"],
    });
    toast.present();
  }
}
