import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss'],
})
export class ImagePopupComponent {
  @Input() src: string = '';

  constructor(private modalCtr: ModalController, public domSanitizer: DomSanitizer) {}

  public closePopup() {
    this.modalCtr.dismiss();
  }
}
