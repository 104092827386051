<ion-header class="image-popup-header">
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button class="close-button" (click)="closePopup()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <pinch-zoom [limitZoom]="10">
    <img [src]="domSanitizer.bypassSecurityTrustUrl(src)" />
  </pinch-zoom>
</ion-content>
