import { modules } from './../../PODO/modules';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { RestService } from '../rest-client/rest-client.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import themes from '../../../environments/configs.json';
import {
  COLOR_KEYS,
  EXCLUDED_ICON_KEYS,
  FONT_KEYS,
  GENERAL_COLOR_KEYS,
  IMAGE_KEYS,
  TEXT_KEYS,
} from './constants/theme-constants';
import { ReplaySubject } from 'rxjs';
import { domains } from '../../../environments/domains';
import { Title } from "@angular/platform-browser";

const WEB_READER_DOMAIN = 'lite';

const WEB_READER_URL = 'https://lite.litello.com';

const STAGING_WEB_READER = 'test-lite';

const EN_PREFIX = 'en-';

const LOCALE_KEY = 'locale';

const DEFAULT_TOOLBAR_LOGO_URL = '/assets/icon/lit-header-logo.png';
const DEFAULT_FOOTER_LOGO_URL = '/assets/icon/lit-footer-logo.png';
const DEFAULT_DASHBOARD_HEADER_LOGO_URL = '/assets/icon/lit-logo.png';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  public isInitialized: ReplaySubject<boolean> = new ReplaySubject(1);

  logo = '';
  customerName: string = environment.defaultTheme.name;
  private customer: any;
  private icons: any;
  public modul: modules = {
    registration: 1,
    dashboard: 1,
    profile: 1,
    aboutLitello: 1,
    credits: 1,
    legalNote: 1,
    visitShop: 1,
    DoubleIcons: 0,
    changePassword: 1,
    contactSupport: 1,
  };

  public toolbarLogoUrl: string | SafeUrl = this.getToolbarLogo();
  public footerLogoUrl: string | SafeUrl = this.getFooterLogo();
  public dashboardHeaderLogoUrl: SafeUrl | undefined = this.getDashboardHeaderLogo();
  public dashboardHeaderLogoUrl2: SafeUrl | undefined = this.getDashboardHeaderLogo("2");
  public dashboardHeaderLogoUrl3: SafeUrl | undefined = this.getDashboardHeaderLogo("3");
  public dashboardHeadline: string | null = this.getDashboardHeadline();
  public dashboardIntroText: string | null = this.getDashboardIntroText();
  public ebookHeadline: string | null = this.getEbookHeadline();

  constructor(
    private restService: RestService,
    private sanitizer: DomSanitizer,
    private route : ActivatedRoute,
    private titleService:Title
  ) { }

  /**
   * Checks if the customer has a theme
   * @param customer Customer name to check for
   * @returns true if customer exists
   */
  public isCustomer(customer: string) {
    return customer in themes;
  }

  /**
   * Injects the theme data into the rendered page
   * @param data Theme data object
   */
  private injectTheme(data: any) {
    this.injectColors(data.colorpalette);
    this.injectIcons(data.icons);
    this.injectImages(data.icons);
    this.changeTitle(data.customer.title);
    this.injectFonts([data.font1, data.font2, data.font3, data.font4, data.font5, data.font6]);
    this.injectTextFonts(data.fonts);
    this.injectText(data.customer);
    this.setCustomer(data.customer);
    this.setIcons(data.icons);
  }

  /**
   * This method is only used by the theme switcher.
   * Loads a theme by the given customer name.
   * @param customer Customer name to load theme for
   */
  public loadThemebyCustomerName(customer: string) {
    this.restService.getThemes(customer).subscribe({
      next: (data) => {
        this.modul = data.modules;
        this.injectTheme(data);
      },
      error: () => {
        console.log('Theme does not exists for customer', customer);
      }
    });
  }

  /**
   * Load theme through the rest service and inject it into the page.
   * 1st priority: URL parameter theme
   * 2nd priority: domain based theme in domains.ts
   * Fallback: Environment variable selectedTheme
   */
  public async loadTheme() {
    this.customerName = environment.selectedTheme;

    const domain = window.location.hostname;
    if (domain in domains) {
      this.customerName = domains[domain].theme;
      this.titleService.setTitle(domains[domain].title);
    }
    
    this.route.queryParams.forEach((params) => {
      if (params.theme) {
        this.customerName = params.theme;
      }
    });
    this.loadThemeData();
  }

  private async loadThemeData() {
    console.log("Loaded theme ", this.customerName);
    this.restService.getThemes(this.customerName).subscribe({
      next: (data) => {
        this.modul = data.modules;
        this.injectTheme(data);
        this.isInitialized.next(true);
      },
      error: () => {
        console.error('No theme exists for customer', this.customerName);
        this.isInitialized.next(true);
      }
    });
  }

  /**
   * Get the value of a property.
   * This method accepts two strings, one for the german and one for the english value.
   * Only one property value will be returned, depending on the current locale.
   * @param keyDE German property key
   * @param keyEN English property key
   * @returns property value or null if not found
   */
  private static getPropertyValue(keyDE: string, keyEN: string): string | null {
    const locale = localStorage.getItem(LOCALE_KEY);
    if (locale && locale.startsWith(EN_PREFIX)) {
      return localStorage.getItem(keyEN);
    }
    return localStorage.getItem(keyDE);
  }

  /**
   * Get the toolbar logo URL
   * @returns URL of the toolbar logo
   */
  private getToolbarLogo(): SafeUrl | string {
    let headerTopRightLogo = localStorage.getItem('--dashboard-top-right-logo');
    if (headerTopRightLogo) {
      return this.sanitizer.bypassSecurityTrustUrl(headerTopRightLogo);
    } else {
      return DEFAULT_TOOLBAR_LOGO_URL;
    }
  }

  /**
   * Get the default toolbar logo URL
   * @returns Default toolbar logo URL
   */
  public getToolbarDefaultLogo(): string {
    return DEFAULT_TOOLBAR_LOGO_URL;
  }

  /**
   * Get the text for the top right of the dashboard
   * @returns Dashboard text of current customer theme
   */
  public getDashboardTopText() {
    return ThemeService.getPropertyValue('--dashboard-top-text-right-de', '--dashboard-top-text-right-en');
  }

  /**
   * Get the logo for the dashboard header
   * @param variant of the logo. Allowed values are 2 and 3
   * @returns URL to the header logo
   */
  private getDashboardHeaderLogo(variant?: string): SafeUrl | string {
    // Ensure variant is a string so that it can be concatenated
    if (!variant) {
      variant = "";
    }

    let headerLogo = localStorage.getItem('--dashboard-header-logo' + variant);
    if (headerLogo) {
      return this.sanitizer.bypassSecurityTrustUrl(headerLogo);
    } else {
      return DEFAULT_DASHBOARD_HEADER_LOGO_URL;
    }
  }


  /**
   * Get the text for the dashboard headline in the current locale
   * @returns Text for the dashboard headline
   */
  private getDashboardHeadline(): string | null {
    return ThemeService.getPropertyValue('--dashboard-headline-text-de', '--dashboard-headline-text-en');
  }

  /**
   * Get the text for the dashboard intro in the current locale
   * @returns Text for the dashboard intro
   */
  private getDashboardIntroText(): string | null {
    return ThemeService.getPropertyValue('--dashboard-intro-text-de', '--dashboard-intro-text-en');
  }

  /**
   * Get the text for the ebook headline in the current locale
   * @returns Text for the ebook headline
   */
  private getEbookHeadline() {
    return ThemeService.getPropertyValue('--dashboard-eBook-headline-text-de', '--dashboard-eBook-headline-text-en');
  }

  /**
   * Get the URL to the footer logo of the current customer
   * @returns URL to the footer logo
   */
  private getFooterLogo() {
    let footerIcon = localStorage.getItem('--dashboard-footer-icon');
    if (footerIcon) {
      return this.sanitizer.bypassSecurityTrustUrl(footerIcon);
    } else {
      return DEFAULT_FOOTER_LOGO_URL;
    }
  }

  /**
   * Get the default footer logo URL
   * @returns Default footer logo URL
   */
  public getFooterDefaultLogo() {
    return DEFAULT_FOOTER_LOGO_URL;
  }

  /**
   * Get the text for the dashboard footer in the current locale
   * @returns text for the dashboard footer
   */
  public getFooterText() {
    return ThemeService.getPropertyValue('--dashboard-footer-text-de', '--dashboard-footer-text-en');
  }

  /**
   * Get the customer logo
   * @returns URL to the logo of the current customer
   */
  public getLogo() {
    return document.documentElement.style.getPropertyValue('--logo');
  }

  /**
   * Set the customer name
   * @param customer string of the customer name
   */
  public setCustomer(customer: any) {
    this.customer = customer;
  }

  /**
   * Set the icons for the current theme
   * @param icons array of icons
   */
  public setIcons(icons: any) {
    this.icons = icons;
  }

  /**
   * Get the web reader URL
   * @returns current URL of the web reader
   */
  public getWebReaderUrl() {
    return WEB_READER_URL;
  }

  /**
   * Inject the theme into the DOM
   * @param colorPalette color palette of the current theme
   */
  public injectColors(colorPalette: any) {
    if (colorPalette) {
      COLOR_KEYS.forEach((colorKey) => {
        let color = colorPalette[colorKey];
        if (color) {
          let propertyKey = colorKey;
          document.documentElement.style.setProperty(propertyKey, color);
        }
      });
      this.injectGeneralColors(colorPalette);
    }
  }

  /**
   * Inject the general colors into the DOM if they are defined in the color palette
   * @param colorPalette color palette of the current theme
   */
  public injectGeneralColors(colorPalette: any) {
    GENERAL_COLOR_KEYS.forEach((colorKey) => {
      let tmpKey = colorKey.replace('-', '_');
      let color = colorPalette[tmpKey];
      if (color) {
        let propertyKey = '--ion-color-' + colorKey;
        document.documentElement.style.setProperty(propertyKey, color);
      }
    });
  }

  /**
   * Inject the images into the DOM
   * Only injects images with allowed keys, so that no unwanted images are injected
   * @param images dictionary of images
   */
  public injectImages(images: any) {
    if (images) {
      IMAGE_KEYS.filter((elem) => !EXCLUDED_ICON_KEYS.includes(elem)).forEach((imageKey) => {
        let image = images[imageKey];

        if (image) {
          let propertyKey = '--' + imageKey;
          const allowedKeys = [
            'dashboard-header-logo',
            'dashboard-header-logo2',
            'dashboard-header-logo3',
            'dashboard-intro-image',
            'dashboard-footer-icon',
            'dashboard-top-right-logo',
            'login-logo',
            'login-logo2',
            'login-logo3',
            'dashboard-header-background-image'
          ];
          if (imageKey in allowedKeys) {
            localStorage.getItem(propertyKey);
            localStorage.setItem(propertyKey, image);
          } else {
            document.documentElement.style.setProperty(propertyKey, image);
          }
        }
      });
    }
  }

  /**
   * Injects the customer texts into the DOM
   * @param textCollection dictionary of texts
   */
  public injectText(textCollection: any) {
    if (textCollection) {
      TEXT_KEYS.forEach((textKey) => {
        let text = textCollection[textKey];
        if (text) {
          let propertyKey = '--' + textKey;
          localStorage.setItem(propertyKey, text);
        }
      });
    }
  }

  /**
   * Inject customer fonts into the DOM
   * @param fonts dictionary of fonts
   */
  public injectTextFonts(fonts: any) {
    if (fonts) {
      FONT_KEYS.forEach((fontKey) => {
        let font = fonts[fontKey];
        if (font) {
          let propertyKey = '--' + fontKey;
          document.documentElement.style.setProperty(propertyKey, font);
        }
      });
    }
  }

  /**
   * Inject customer icons into the DOM
   * @param icons logo of the current customer
   */
  public injectIcons(icons: any) {
    if (icons && icons.logo) {
      let link = document.querySelector("link[rel*='icon']");
      // @ts-ignore
      link = icons.logo;
    }
  }

  public changeTitle(title: any) {
    document.title = title;
  }

  public injectFonts(fonts: any[]) {
    ThemeService.createFonts(fonts);
    fonts.forEach((font, index) => {
      document.documentElement.style.setProperty(`--ion-font-family${index}`, font.family);
    });
  }

  // This is a bad solution and should be replaced with a better one
  // The styles and weights are hardcoded and should be dynamic
  // That may require a change in the backend
  /**
   * Injects the customer fonts into the DOM
   * @param fonts array of fonts with exactly 6 elements
   */
  private static createFonts(fonts: any[]) {
    const fontStyles = `
        <style>
            @font-face {
              font-family: '${fonts[0].family}';
              src: url('${fonts[0].src}');
              font-style:   normal;
              font-weight:  700;
            }
            
            @font-face {
              font-family: '${fonts[1].family}';
              src: url('${fonts[1].src}');
              font-style:   italic;
              font-weight:  400;
            }
            
            @font-face {
              font-family: '${fonts[2].family}';
              src: url('${fonts[2].src}');
              font-style:   normal;
              font-weight:  400;
            }
            
            @font-face {
              font-family: '${fonts[3].family}';
              src: url('${fonts[3].src}');
              font-style:   normal;
              font-weight:  400;
            }
            
            @font-face {
              font-family: '${fonts[4].family}';
              src: url('${fonts[4].src}');
              font-style:   normal;
              font-weight:  700;
            }
            
            @font-face {
              font-family: '${fonts[5].family}';
              src: url('${fonts[5].src}');
              font-style:   normal;
              font-weight:  400;
            }
        </style>
        `;
    const head = document.querySelector('head');
    if (head) {
      head.innerHTML = head.innerHTML + '\n' + fontStyles;
    }
  }

  /**
   * Returns the URL of the logo of the customer
   * @param secondary if true, returns the secondary logo which is only used if the double icons module is enabled
   * @returns URL of the logo of the current customer
   */
  public getSideBarLogo(secondary: boolean) {
    if (this.icons && this.icons.logo) {
      return this.icons["logo" + (secondary ? "2" : "")];
    } else {
      return '/assets/icon/bookIconYellow.svg'; // default
    }
  }

  /**
   * Check whether a user is allowed to access the current customer using the allowedUsers array in the environment
   * This is for testing purposes only
   * @param email to check
   * @returns boolean whether the email is allowed to access the current customer
   */
  private static temporaryAllowedCheck(email: string) {
    let allowedMails: Array<string> = environment.allowedUsers;
    for (let e of allowedMails) {
      if (e.toLowerCase() === email.toLowerCase()) {
        return true;
      }
    }
  }

  /**
   * Check if the URL is an IP address
   * @param url URL to check
   * @returns boolean indicating whether the URL is an IP address
   */
  private static isIPAddress(url: string) {
    return RegExp('^(([1-9]?\\d|1\\d\\d|2[0-4]\\d|25[0-5])(\\.(?!$)|(?=$))){4}$').test(url);
  }
}
