import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PluginListenerHandle } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { IonContent } from '@ionic/angular';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { ClientBook } from '../../../PODO/clientBook';
import { ToastService } from '../../../services/toast/toast.service';
import { DeviceDetectorService } from '../../../util/device-detector/device-detector.service';

@Component({
  selector: 'app-collection-overview',
  templateUrl: './collection-overview.component.html',
  styleUrls: ['./collection-overview.component.scss'],
})
export class CollectionOverviewComponent implements OnInit, OnDestroy {
  @Input('book') currentlyOpenedBook: ClientBook | undefined;
  @Input() collection: ClientBook[] = [];

  @Output() togglePanel = new EventEmitter();

  @ViewChild(IonContent) content: IonContent | undefined;

  scrollPosition: number = 0;

  private isNetworkConnected: boolean | undefined;
  private networkListenerHandle: PluginListenerHandle;

  constructor(
    private translationService: L10nTranslationService,
    private deviceDetector: DeviceDetectorService,
    private screenOrientation: ScreenOrientation,
    private router: Router,
    private toastService: ToastService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {

    this.screenOrientation.onChange().subscribe(async () => {
      if (!this.deviceDetector.isDesktop()) {
        if (this.content) {
          await this.content.scrollToPoint(0, this.scrollPosition);
        }
      }
    });

    this.networkListenerHandle = Network.addListener('networkStatusChange', async (status: ConnectionStatus) => {
      this.isNetworkConnected = status.connected;
    });
  }
  
  async ngOnInit(): Promise<void> {
    this.isNetworkConnected = (await Network.getStatus()).connected;
  }

  ngOnDestroy(): void {
    this.networkListenerHandle.remove();
  }

  onIonScroll(event: any) {
    if (event.detail.scrollTop !== 0) {
      this.scrollPosition = event.detail.scrollTop;
    }
  }

  public openBook(book: ClientBook) {
    if (book) {
      this.router
        .navigate(['/book', book.id])
        .then((navigated) => {
          if (navigated) {
            this.togglePanel.emit();
            window.location.reload();
          } else {
            console.error('Route is not navigated');
            this.toastService.showErrorMessage(this.translationService.translate('UnknownError'));
          }
        })
        .catch((error) => {
          console.error(error);
          this.toastService.showErrorMessage(this.translationService.translate('UnknownError'));
        });
    }
  }
}
