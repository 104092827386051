import { trigger, transition, style, animate, state, AnimationMetadata } from '@angular/animations';

const defaultAnimationTimeAndEasing = '200ms ease-in';
const defaultAnimate = animate(defaultAnimationTimeAndEasing);
export const Animations = [
  trigger('slidesFadeInOut', []),
  trigger('zoomFadeInOut', [
    state('reader', style({ boxShadow: '0 0 0 0 #ccc', transform: 'scale(1)' })),
    state('flippage', style({ boxShadow: '0 0 4px #ccc', transform: 'scale(0.7)' })),
    transition('* => *', [defaultAnimate]),
  ]),
  trigger('slideInOutHeader', [
    state('void', style({ transform: 'translateY(-100%)' })),
    state('flippage', style({ transform: 'translateY(0%)' })),
    transition('* => *', [defaultAnimate]),
  ]),
  trigger('slideInOutFooter', [
    state('void', style({ transform: 'translateY(100%)' })),
    state('flippage', style({ transform: 'translateY(0%)' })),
    transition('* => *', [defaultAnimate]),
  ]),
];
